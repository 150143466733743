import { createSocket } from '@/api'
import { NotificationsController }     from '@/controllers/notifications/NotificationsController'
import { canPerformAction }        from '@/helpers/canPerformAction'
import { UserService }             from '@/services/user'
import { DocumentPresetResource }  from '@/models/documents/DocumentPresetResource'
import { DocumentsService }        from '@/services/documents'
import { OrganisationDocumentsService } from '@/services/organisationDocuments'
import { orderBy }                 from 'lodash'
import { Socket }                  from 'socket.io-client'

export type AuthUserPermissions =
    | 'can_upload_document'
    | 'can_start_project'
    | 'can_create_preset'
    | 'can_access_projects'
    | 'can_approve_policy'
    | 'can_copy_project'
    | 'can_create_implementation'

export type NotificationSettings = {
  [key: string]: 'daily' | 'weekly' | 'none'
}

export interface IAuthUserResource {
  readonly id: number
  organisation_roles: string[]
  username: string
  name: string
  email: string
  organisation_id: number
  image: string
  created_at: string
  updated_at: string
  environment: string
  permissions: AuthUserPermissions[]
  channel: string
  notification_settings?: NotificationSettings
}

const documentService = new DocumentsService()
const organisationService = new OrganisationDocumentsService()

export class AuthUserResource implements IAuthUserResource {
  public readonly id: number
  public organisation_roles: string[]
  public username: string
  public name: string
  public email: string
  public organisation_id: number
  public image: string
  public created_at: string
  public updated_at: string
  public environment: string
  public permissions: AuthUserPermissions[]
  public channel: string
  public notification_settings: NotificationSettings

  public notifications: NotificationsController

  public isFetchingPresets: boolean = true
  public libraryPresets: DocumentPresetResource[] = []

  private socket?: Socket
  private service: UserService = new UserService()

  constructor(user: IAuthUserResource) {
    this.id = user.id
    this.organisation_roles = user.organisation_roles
    this.username = user.username
    this.name = user.name
    this.email = user.email
    this.organisation_id = user.organisation_id
    this.image = user.image
    this.created_at = user.created_at
    this.updated_at = user.updated_at
    this.permissions = user.permissions
    this.environment = user.environment
    this.channel = user.channel
    this.notification_settings = user.notification_settings ?? {}

    this.notifications = new NotificationsController({ service: this.service, namespace: 'notifications' })

    this.openSocket()
    this.getPresets()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public async getPresets(): Promise<void> {
    this.isFetchingPresets = true
    try {
      const { data } = await documentService.presets()
      this.libraryPresets = orderBy(data, ['key'], ['asc'])
    } finally {
      this.isFetchingPresets = false
    }
  }

  // First letter and last letter of last word
  public get initials(): string {
    const NAME = this.name.split(' ')
    const LETTERS = NAME.map((name: string) => name.charAt(0))

    if (LETTERS.length > 1) {
      return LETTERS[0] + LETTERS[LETTERS.length - 1]
    }

    return LETTERS[0]
  }

  public canPerformAction(key: AuthUserPermissions): boolean {
    return canPerformAction(this.permissions, key)
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case 'project notification':
      case 'user notification':
        this.notifications.refresh()
        break
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}

