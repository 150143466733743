import { AxiosPromise, AxiosResponse } from 'axios'
import { BaseService } from '@/services/base'

import { LoginRequest } from '@/requests/auth/LoginRequest'
import { ChangePasswordRequest } from '@/requests/auth/ChangePasswordRequest'
import { ForgotPasswordRequest } from '@/requests/auth/ForgotPasswordRequest'

import { AuthUserResource, IAuthUserResource } from '@/models/users/AuthUserResource'
import { ChangeExpiredPasswordRequest } from '@/requests/auth/ChangeExpiredPasswordRequest'
import { ResetPasswordRequest } from '@/requests/auth/ResetPasswordRequest'

export class AuthService extends BaseService {
  constructor() {
    super('auth')
  }

  /**
   * Login user
   */
  public async login(user: LoginRequest): Promise<AuthUserResource> {
    const { data } = await this.client.post<DetailResponse<IAuthUserResource>>(`${this.namespace}/login`, user)
    return new AuthUserResource(data.data)
  }

  /**
   * Login user by msal token
   */
  public async loginByToken(token: string) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }
    const { data } = await this.client.get<DetailResponse<IAuthUserResource>>(`${this.namespace}/token_login`, config)
    return new AuthUserResource(data.data)
  }

  /**
   * Request reset password
   */
  public async forgotPassword(body: ForgotPasswordRequest): Promise<DetailResponse<string>> {
    const { data } = await this.client.post<DetailResponse<string>>(`${this.namespace}/forgot_password`, body)
    return data
  }

  /**
   * Reset password
   */
  public async resetPassword(body: ResetPasswordRequest): Promise<DetailResponse<string>> {
    const { data } = await  this.client.post<DetailResponse<string>>(`${this.namespace}/reset_password`, body)
    return data
  }

  /**
   * Change password
   */
  public changePassword(user: ChangePasswordRequest): AxiosPromise<void> {
    return this.client.post<void>(`${this.namespace}/password`, user)
  }

  /**
   * Renew an expired password.
   *
   * @param form
   */
  public renew(form: ChangeExpiredPasswordRequest): AxiosPromise<void> {
    return this.client.post<void>(`${this.namespace}/renew`, form)
  }
}
