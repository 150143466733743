import { io }                        from 'socket.io-client'
import { stateModule }               from '@/store'
import { stringifyQuery }            from '@/helpers/query'
import Axios, { AxiosRequestConfig } from 'axios'
import { msalPluginInstance } from "@/plugins/msal-plugin";

const API = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URI,
  withCredentials: true,
  headers: {
    'Accept-Language': 'nl',
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: (params): string => {
    return stringifyQuery(params)
  },
})

API.interceptors.request.use(async (request: any) => {
  request.headers['Pragma'] = 'no-cache'

  try {
      const token = await msalPluginInstance.acquireToken()
      if (token) {
          request.headers['Authorization'] = 'Bearer ' + token.idToken.rawIdToken
      }
      return request
  }
  catch (error) {
  }
})

API.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const isAuthPage = window.location.href.includes('login') || window.location.href.includes('settings') || window.location.href.includes('expired')
    if (error?.response?.data?.message && !isAuthPage) {
      
      stateModule.setNotification({
        message: error.response.data.message,
        errors: error.response.data.errors,
        type: 'error',
      })
    }
    if (!error.response) {
      
      return Promise.reject(error)
    } else if (error.response.status === 401) {
      
      msalPluginInstance.signOut()
      // setTimeout(() => {
      //   window.location.href = '/login'
      // }, 500)
    } else if ((error.response.status >= 400 && error.response.status <= 410) || error.response.status === 500) {
      return Promise.reject(error.response.data)
    } else if (error.response.status === 419) {
      return Promise.reject(error.response)
    }
    return Promise.reject(error.response.data)
  },
)

const createSocket = async (channel: string) => {
  const socket = io(`${process.env.VUE_APP_SOCKET_URI}/${channel}`, {
    withCredentials: true,
    autoConnect: false,
    multiplex: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000,
    transports: ['websocket'],
  })
  await sleep(2000)
  socket.connect()
  return socket
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export { API, createSocket, sleep }
