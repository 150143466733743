import { render, staticRenderFns } from "./DirectConnections.vue?vue&type=template&id=64caa327&scoped=true&"
import script from "./DirectConnections.vue?vue&type=script&lang=ts&"
export * from "./DirectConnections.vue?vue&type=script&lang=ts&"
import style0 from "./DirectConnections.vue?vue&type=style&index=0&id=64caa327&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64caa327",
  null
  
)

export default component.exports