











import {Component, Vue, Prop, Ref} from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ default: true })
  private show!: boolean

  @Prop()
  private name!: string

  private mounted() {
    window.addEventListener('keydown', this.keyboardClose)
  }

  private beforeDestroy() {
    window.removeEventListener('keydown', this.keyboardClose)
  }

  private keyboardClose(event: KeyboardEvent) {
    if (event.key === 'Escape') this.closeModal()
  }

  private closeModal(): void {
    this.$emit('close', this.name)
  }
}
