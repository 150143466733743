import { Component, Vue, Prop } from 'vue-property-decorator'
import { FlexibleDocumentWidgetCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'
import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'


@Component
export class WidgetMixin extends Vue {

  @Prop({ required: false, default: null })
  public item!: FlexibleDocumentWidgetCollectionResource

  @Prop({ default: false })
  public readonly!: boolean

  @Prop({ default: false })
  private canEdit!: boolean

  @Prop({ default: false })
  private canSuggest!: boolean

  @Prop({ default: false })
  private canCreate!: boolean

  @Prop({ default: false })
  private edit!: boolean

  @Prop({ default: false })
  private suggest!: boolean

  public get canOpenEdit(): boolean {
    return this.$route.fullPath.includes(this.item.uuid) && !this.readonly && this.canEdit
  }

  public get canOpenSuggest(): boolean {
    return this.$route.fullPath.includes(this.item.uuid) && !this.readonly && this.canSuggest
  }

  public get items(): FlexibleDocumentComponentCollectionResource[] {
    return this.item.items ?? []
  }

  public updateItem(item: FlexibleDocumentComponentCollectionResource, value: any): void {
    item.updateComponentValue(value)
  }

  public async goToPage(): Promise<void> {
    // Dont redirect if the element is already open or is in readonly state
    if (!this.canOpenEdit) {
      try {
        await this.$router.push({
          name: 'projects-flexible-document-element',
          params: { element_id: this.item.uuid },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  // This page exist because it can happen that when a child is open on a section
  // the section it self is also open and there is no way to get back to this section,
  // So you can add an extra click handler an the header for example to go back
  public async goToPageAnyway(): Promise<void> {
    if (!this.readonly && this.canEdit) {
      try {
        await this.$router.push({
          name: 'projects-flexible-document-element',
          params: { element_id: this.item.uuid },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  public save(): void {
    if (this.suggest) this.item.postSuggestion()
    if (this.edit) this.item.patchComponents()
    this.$router.push({ name: this.$route.name ?? '', params: this.$route.params })
  }

  public cancel(): void {
    if (this.suggest) this.item.disableSuggest()
    if (this.edit) this.item.disableEdit()
    this.$router.push({ name: this.$route.name ?? '', params: this.$route.params })
  }

}
