import { RouteConfig, Route, RawLocation } from 'vue-router'
import { userModule }                      from '@/store'

const requiresAuth = true

export const ROUTES: RouteConfig[] = [
  {
    path: '/ui',
    component: () => import('@/views/Ui.vue'),
    name: 'ui',
    meta: { requiresDev: true }
  },
  {
    path: '/library',
    redirect: { name: 'organisation-documents-overview' }
  },
  {
    path: '/library/public',
    redirect: { name: 'organisation-documents-overview' }
  },
  {
    path: '/library/*',
    redirect: { name: 'projects-overview' }
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index.vue'),
    name: 'dashboard',
    meta: { requiresAuth },
    children: [
      {
        path: 'settings',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/settings/Index.vue'),
        children: [
          {
            path: '',
            name: 'settings-profile',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/settings/Profile.vue')
          }
        ]
      },
      {
        path: 'projects',
        name: 'projects-overview',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/Index.vue')
      },
      {
        path: 'implementations',
        name: 'implementations-overview',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/Index.vue')
      },
      {
        path: 'library',
        name: 'library',
        meta: { requiresAuth },
        // redirect: { name: 'public-documents-overview' },
        component: () => import('@/views/dashboard/documents/Index.vue'),
        children: [
          {
            path: 'public',
            name: 'public-documents-overview',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/documents/public/Index.vue'),
            children: [
              {
                path: ':document_id',
                meta: { requiresAuth },
                component: () => import('@/views/dashboard/documents/public/Detail.vue'),
                children: [
                  {
                    path: '',
                    name: 'public-documents-single',
                    meta: { requiresAuth, scrollPage: false },
                    component: () => import('@/views/dashboard/documents/public/detail/Detail.vue')
                  },
                  {
                    path: 'notes',
                    name: 'public-documents-single-notes',
                    meta: { requiresAuth, scrollPage: false },
                    component: () => import('@/views/dashboard/documents/public/detail/Notes.vue')
                  }
                ]
              }
            ]
          },
          {
            path: 'organisation',
            name: 'organisation-documents-overview',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/documents/organisation/Index.vue'),
            children: [
              {
                path: ':document_id',
                meta: { requiresAuth },
                component: () => import('@/views/dashboard/documents/organisation/Detail.vue'),
                children: [
                  {
                    path: '',
                    name: 'organisation-documents-single',
                    meta: { requiresAuth },
                    component: () => import('@/views/dashboard/documents/organisation/detail/Detail.vue')
                  },
                  {
                    path: 'history',
                    name: 'organisation-documents-single-history',
                    meta: { requiresAuth },
                    component: () => import('@/views/dashboard/documents/organisation/detail/History.vue')
                  },
                  {
                    path: 'notes',
                    name: 'organisation-documents-single-notes',
                    meta: { requiresAuth },
                    component: () => import('@/views/dashboard/documents/organisation/detail/Notes.vue')
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/implementations/:implementation_id(\\d+)/entities/:entity_id(\\d+)',
    meta: { requiresAuth },
    component: () => import('@/views/dashboard/implementations/entity/Detail.vue'),
    children: [
      {
        path: '',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/entity/implementations/Index.vue'),
        children: [
          {
            path: '',
            name: 'implementation-entity-implementations',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/implementations/entity/implementations/Questions.vue')
          },
          {
            path: 'proposals/:proposal_id(\\d+)',
            name: 'implementation-entity-proposal',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/implementations/entity/implementations/Detail.vue')
          }
        ]
      },
      {
        path: 'team',
        name: 'implementation-entity-team',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/entity/team/Index.vue'),
      },

      {
        path: 'document',
        name: 'implementation-entity-document',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/document/Index.vue'),
      }
    ]
  },
  // Single implementation
  {
    path: '/implementations/:implementation_id(\\d+)',
    meta: { requiresAuth },
    component: () => import('@/views/dashboard/implementations/Detail.vue'),
    children: [
      {
        path: '',
        name: 'implementation-dashboard',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/dashboard/Index.vue'),
      },
      {
        path: 'team',
        name: 'implementation-team',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/team/Index.vue'),
      },
      {
        path: 'scope',
        name: 'implementation-scope',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/scope/Index.vue'),
      },
      {
        path: 'proposals',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/implementations/Index.vue'),
        children: [
          {
            path: '',
            name: 'implementation-questions',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/implementations/implementations/Questions.vue')
          },
          {
            path: ':proposal_id(\\d+)',
            name: 'implementation-proposal',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/implementations/implementations/Detail.vue')
          }
        ]
      },
      {
        path: 'document',
        name: 'implementation-document',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/implementations/document/Index.vue'),
      }
    ]
  },
  // Single project
  {
    path: '/projects/:project_id(\\d+)',
    meta: { requiresAuth },
    component: () => import('@/views/dashboard/projects/Detail.vue'),
    children: [
      {
        path: '',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/Index.vue'),
        children: [
          {
            path: '',
            name: 'projects-single-detail',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/index/Overview.vue')
          },
          {
            path: 'connections',
            name: 'projects-single-detail-connections',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/index/Connections.vue')
          },
          {
            path: 'documents',
            name: 'projects-single-detail-documents',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/index/Documents.vue')
          },
          {
            path: 'team',
            name: 'projects-single-detail-team',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/index/Team.vue')
          }
        ]
      },
      {
        path: 'all-documents',
        name: 'all-documents',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/document-templates/DocumentTemplates.vue')
      },
      {
        path: 'multi-documents',
        name: 'multi-documents',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/multi-documents/MultiDocuments.vue')
      },
      {
        path: 'documents/:document_id?',
        props: true,
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/Document.vue'),
        children: [
          {
            path: '',
            props: true,
            name: 'projects-detail-document',
            meta: { requiresAuth },
            component: () => import('@/components/document/Viewer.vue')
          },
          {
            path: 'proposal/:proposal_id(\\d+)',
            props: {
              default: true,
              side: true
            },
            name: 'projects-detail-document-detail',
            meta: { requiresAuth },
            components: {
              default: () => import('@/components/document/Viewer.vue'),
              side: () => import('@/views/dashboard/projects/detail/document/proposals/Detail.vue')
            }
          },
          {
            path: 'proposals',
            props: true,
            name: 'projects-detail-document-proposals',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/document/Proposals.vue')
          },
          {
            path: 'proposals/:proposal_id(\\d+)',
            props: {
              default: true,
              side: true
            },
            name: 'projects-detail-document-proposal-detail',
            meta: { requiresAuth },
            components: {
              default: () => import('@/views/dashboard/projects/detail/document/Proposals.vue'),
              side: () => import('@/views/dashboard/projects/detail/document/proposals/Detail.vue')
            }
          }
        ]
      },
      {
        path: 'process/:process_id(\\d+)',
        name: 'projects-detail-process',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/Process.vue'),
        children: [
          {
            path: 'proposal/:proposal_id(\\d+)',
            name: 'projects-detail-proposal-detail',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/process/Detail.vue')
          }
        ]
      },
      {
        path: 'flexible-document/:template_id',
        name: 'projects-flexible-document',
        meta: { requiresAuth },
        component: () => import('@/views/dashboard/projects/detail/flexible-document/Index.vue'),
        children: [
          {
            path: 'details',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/flexible-document/detail/Index.vue'),
            children: [
              {
                path: '',
                name: 'projects-flexible-document-details',
                meta: { requiresAuth },
                component: () => import('@/views/dashboard/projects/detail/flexible-document/detail/Details.vue'),
              },
              {
                path: 'comments',
                name: 'projects-flexible-document-comments',
                meta: { requiresAuth },
                component: () => import('@/views/dashboard/projects/detail/flexible-document/detail/Comments.vue'),
              },
              {
                path: 'activity',
                name: 'projects-flexible-document-activity',
                meta: { requiresAuth },
                component: () => import('@/views/dashboard/projects/detail/flexible-document/detail/Activity.vue'),
              }
            ]
          },
          {
            path: 'section/:element_id/process/:process_id(\\d+)/proposal/:proposal_id(\\d+)',
            name: 'projects-flexible-document-proposal',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/flexible-document/Proposal.vue')
          },
          {
            path: 'section/:element_id',
            name: 'projects-flexible-document-element',
            meta: { requiresAuth },
            component: () => import('@/views/dashboard/projects/detail/flexible-document/Element.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/auth/Index.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue')
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import('@/views/auth/Forgot.vue')
      },
      {
        path: 'reset',
        name: 'reset',
        component: () => import('@/views/auth/Reset.vue')
      },
      {
        path: 'expired',
        name: 'expired',
        component: () => import('@/views/auth/Expired.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: { name: 'projects' }
  }
]

