





































































































































































































import isEqual from 'lodash/isEqual'
import {projectModule} from '@/store'
import cloneDeep from 'lodash/cloneDeep'
import { mixin as clickaway } from '@/plugins/vue-clickaway'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import {ProcessResource} from '@/models/process/ProcessResource'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'
import {ProposalSummaryCollectionResource} from '@/models/proposals/ProposalSummaryCollectionResource'
import {FlexibleDocumentComponentCollectionResource} from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import Modal from '@/components/modals/Default.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import CheckboxInput from '@/components/inputs/Checkbox.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalSimple from '@/components/tiles/ProposalSimple.vue'
import SelectProcessContextMenu from './SelectProcessContextMenu.vue'
import DateInput from '@/components/inputs/Date.vue'

import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'

type ProcessProposalParamsComponentValues = {
  [key: string]: string[]
}

@Component({
  name: 'DataObjectModalSelectProcess',
  components: {
    Modal,
    DateInput,
    SearchInput,
    SwitchInput,
    CheckboxInput,
    SmallLoader,
    DropdownInput,
    ProposalSimple,
    SelectProcessContextMenu,
  },
  mixins: [clickaway],
})
export default class DataObjectModalSelectProcess extends Vue {
  @Prop()
  private title!: string

  @Prop()
  private loading!: boolean

  @Prop() private data!: any

  @Prop({default: false})
  private open!: boolean

  @Prop({required: true})
  private process_id!: FlexibleDocumentComponentCollectionResource

  @Prop({required: true})
  private status!: FlexibleDocumentComponentCollectionResource

  @Prop({required: true})
  private proposal_ids!: FlexibleDocumentComponentCollectionResource

  @Prop({ required: false })
  private process_filters!: FlexibleDocumentComponentCollectionResource

  @Prop({required: false})
  private previous_process_id!: FlexibleDocumentComponentCollectionResource | null

  @Prop({required: false})
  private next_process_id!: FlexibleDocumentComponentCollectionResource | null

  @Prop({required: false})
  private process_component_selection!: FlexibleDocumentComponentCollectionResource

  @Prop({default: () => []})
  private readonly blacklistedComponentIds!: number[]

  @Prop() public metaTags!: any

  private loadingProposals: boolean = false

  private new_process_id: string = ''

  private new_status: string[] = []
  private new_parent_ids: number[] = []
  private new_child_ids: number[] = []
  private new_component_selection: number[] = []
  private start_date: string = ''
  private end_date: string = ''

  private newProcessProposalParamsComponentValues: ProcessProposalParamsComponentValues = {}
  private defaultFilters: ProcessProposalParamsComponentValues = {}
  private proposalFilters: { [key: string]: { [key: string]: string } } = {}
  private search: string = ''

  private proposals: ProposalSummaryCollectionResource[] = []
  private previousProposals: ProposalSummaryCollectionResource[] | null = null
  private nextProposals: ProposalSummaryCollectionResource[] | null = null

  private selectedProposalIds: number[] = []
  private autofill: boolean = false

  private filterProposalsOpen: boolean = false
  private dataSelectionOpen: boolean = false

  private get processes(): ProcessResource[] {
    return projectModule.processes
  }

  private get process(): ProcessResource | null {
    return projectModule.getProcessById(+this.new_process_id)
  }

  private get statusFilters(): Array<Partial<StatusResourceCollection>> {
    let filters: Array<Partial<StatusResourceCollection>> = []

    if (this.process?.statuses) {
      filters = [...this.process.statuses ]
    }

    return filters
  }

  private get selectableProcesses(): ProcessResource[] {
    if (this.previousProcess) {
      const previousProcessOrder = this.previousProcess.order
      return this.processes.filter(({ order }) => order > previousProcessOrder).sort((a, b) => a.order - b.order)
    } else if (this.nextProcess) {
      const nextProcessOrder = this.nextProcess.order
      return this.processes.filter(({ order }) => order < nextProcessOrder).sort((a, b) => a.order - b.order)
    } else {
      return this.processes.sort((a, b) => a.order - b.order)
    }
  }

  private get componentDetailOptions(): SelectItem[] {
    if (this.process) {
      return this.process.widgets
        .map((widget) => widget.components
          .filter(({label, visible}) => !(label === '' || label === 'document_id' || !visible))
          .map(({label, id}) => ({ label: label ?? '', value: id  }))
        )
        .flat()
    }
    return []
  }

  private get previousProcess(): ProcessResource | null {
    return projectModule.getProcessById(parseInt(this.previous_process_id?.component_value ?? '0' as string, 10))
  }
  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessById(parseInt(this.next_process_id?.component_value ?? '0' as string, 10))
  }

  private get previousProcessForSingleProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) - 1 ?? 0)
  }
  private get nextProcessForSingleProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) + 1 ?? 0)
  }

  private get previousProcessForSingleProcessName(): string {
    return this.previousProcessForSingleProcess?.name ?? ''
  }

  private get nextProcessForSingleProcessName(): string {
    return this.nextProcessForSingleProcess?.name ?? ''
  }

  private get previousProposalOptions(): SelectItem[] {
    return this.previousProposals?.map(({proposal_name, id}) => ({label: proposal_name, value: id})) ?? []
  }

  private get nextProposalOptions(): SelectItem[] {
    return this.nextProposals?.map(({proposal_name, id}) => ({label: proposal_name, value: id})) ?? []
  }

  private get proposalIndex(): ProposalIndexRequest {
    return {
      status: this.new_status,
      parent_ids: this.new_parent_ids,
      child_ids: this.new_child_ids,
      search: this.search,
      start_date: this.start_date,
      end_date: this.end_date,
      component_values: this.newProcessProposalParamsComponentValues,
      component_ids: this.new_component_selection
    }
  }

  private setProcessFilters() {
    const filters = this.process_filters?.component_value
    if (filters) {
      // When filters are filled they wanted to autofill so we make it true here already
      this.autofill = true
      if (Array.isArray(filters.status)) {
        this.new_status = filters.status
      }
      if (Array.isArray(filters.parent_ids)) {
        this.new_parent_ids = filters.parent_ids
      }
      if (Array.isArray(filters.child_ids)) {
        this.new_child_ids = filters.child_ids
      }
      if (typeof filters.search === 'string') {
        this.search = filters.search
      }
      if (typeof filters.start_date === 'string') {
        this.start_date = filters.start_date
      }
      if (typeof filters.end_date === 'string') {
        this.end_date = filters.end_date
      }
      if (typeof filters.component_values === 'object') {

        for (const component_id in filters.component_values) {
          if (Array.isArray(filters.component_values[component_id])) {
            this.newProcessProposalParamsComponentValues[component_id] = filters.component_values[component_id]
          } else if (Array.isArray(this.process?.initialFilterValues[component_id])) {
            const initialFilters = this.process?.initialFilterValues[component_id]
            this.newProcessProposalParamsComponentValues[component_id] = Array.isArray(initialFilters) ? initialFilters : []
          } else {
            this.newProcessProposalParamsComponentValues[component_id] = []
          }
        }
      } else {
        this.newProcessProposalParamsComponentValues = this.process?.initialFilterValues ?? {}
      }
    }
  }

  private setInitialSelection(): void {
    if (this.proposal_ids?.component_value) {
      this.selectedProposalIds = this.proposal_ids.component_value.split(',').map((id: string) => +id)
    }
  }

  private setComponentSelection(): void {
    if (this.process_component_selection?.component_value) {
      const componentSelection = this.componentDetailOptions.map((option) => option.value) as number[]
      const blacklistedComponents = this.process_component_selection?.component_value as number[]

      this.new_component_selection = this.getViewableComponents(componentSelection, blacklistedComponents)
    }
  }

  private getViewableComponents(components: number[], blacklistedComponents: number[]): number[] {
    return components.filter((component) => !blacklistedComponents.includes(component))
  }

  private get allProposalsSelected(): boolean {
    return this.proposals.length > 0 && this.selectedProposalIds.length >= this.proposals.length
  }

  private get filtersActive(): boolean {
    return !isEqual({ ...this.newProcessProposalParamsComponentValues }, this.defaultFilters)
  }

  @Watch('open')
  private async resetValues(): Promise<void> {
    if (this.open) {
      this.filterProposalsOpen = false
      this.dataSelectionOpen = false
      this.new_process_id = typeof this.process_id.component_value === 'string' ? this.process_id.component_value : ''
      this.new_status = this.status.component_value instanceof Array ? [...this.status.component_value.flat()] : []
      this.newProcessProposalParamsComponentValues = this.process?.initialFilterValues ?? {}
      this.getSiblingProposals()
      this.setInitialFilters()
      this.setProcessFilters()
      this.setInitialSelection()
      this.setComponentSelection()
      await this.getProposals()
    }
  }

  @Watch('new_process_id')
  private onProcessChange(val: string, oldVal: string): void {
    if (oldVal !== '' && !!val) {
      this.resetProposalFilters()
      this.new_component_selection = []
    }
  }

  private async resetProposalFilters(): Promise<void> {
    this.new_status = []
    this.new_parent_ids = []
    this.new_child_ids = []
    this.search = ''
    this.start_date = ''
    this.end_date = ''

    this.filterProposalsOpen = false
    this.newProcessProposalParamsComponentValues = this.process?.initialFilterValues ?? {}

    await this.getProposals()
    this.setInitialFilters()
    this.setComponentSelection()
  }

  private setInitialFilters() {
    for (const { id } of this.process?.componentFilters ?? []) {
      if (this.newProcessProposalParamsComponentValues) {
        Vue.set(this.newProcessProposalParamsComponentValues, id, [])
      }
    }
    // @ts-ignore
    for (const { id } of this.proposalFilters?.components ?? []) {
      if (this.newProcessProposalParamsComponentValues) {
        Vue.set(this.newProcessProposalParamsComponentValues, id, [])
      }
    }

    this.defaultFilters = cloneDeep(this.newProcessProposalParamsComponentValues)
  }

  private get parentFilters(): SelectItem[] {
    if (this?.proposalFilters?.parents && this.previousProcessForSingleProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.previousProcessForSingleProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.parents).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.parents[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private get childFilters(): SelectItem[] {
    if (this.proposalFilters?.children && this.nextProcessForSingleProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.nextProcessForSingleProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.children).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.children[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private toggleSelectAll(): void {
    if (this.allProposalsSelected) {
      this.selectedProposalIds = []
    } else {
      this.selectedProposalIds = []
      this.selectedProposalIds.push(...this.proposals.map((proposal) => proposal.id))
    }
  }

  private async getProposals(): Promise<void> {
    try {
      this.filterProposalsOpen = false
      this.loadingProposals = true
      this.proposals = []
      if (this.process) {
        const data = await this.process.getProposalsIndex(this.proposalIndex)
        this.proposals = data.data
        this.proposalFilters = data.filters
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loadingProposals = false
    }
  }

  private selectProcess(payload: { process_id: string, components: number[] }) {
    this.new_process_id = payload.process_id
    this.new_component_selection = payload.components

    const componentSelection = this.componentDetailOptions.map((option) => option.value) as number[]
    const blacklistedComponents = this.getViewableComponents(componentSelection, this.new_component_selection)
    this.process_component_selection.updateComponentValue(blacklistedComponents)
    this.process_id.updateComponentValue(this.new_process_id)

    this.dataSelectionOpen = false
    this.getProcessAndProposals()
  }

  private async getProcessAndProposals(): Promise<void> {
    await this.getProposals()
    this.setInitialFilters()
  }

  private async getSiblingProposals() {
    try {
      if (this.nextProcess) {
        const { data: nextProposals } = await this.nextProcess.getProposalsIndex({})
        this.nextProposals = nextProposals
      }
      if (this.previousProcess) {
        const { data: previousProposals } = await this.previousProcess.getProposalsIndex({})
        this.previousProposals = previousProposals
      }
    } catch (e) {
      console.error(e)
    }
  }

  private updateSelectedProposalIds(proposal: ProposalSummaryCollectionResource): void {
    if (this.selectedProposalIds.includes(proposal.id)) {
      const index = this.selectedProposalIds.findIndex((id) => id === proposal.id)
      if (index > -1) {
        this.selectedProposalIds.splice(index, 1)
      }
    } else {
      this.selectedProposalIds.push(proposal.id)
    }
  }

  private get getSaveText(): string {
    if (this.autofill) {
      return `Save (autofill)`
    } else {
      return `Save (${this.selectedProposalIds.length})`
    }
  }

  private save(): void {
    if (this.autofill) {
      this.includeAutoFill()
    } else {
      this.includeSelected()
    }
  }

  private toggleDataSelection(): void {
    this.dataSelectionOpen = !this.dataSelectionOpen
  }

  private closeDataSelection(): void {
    this.dataSelectionOpen = false
  }

  private closeFilters(): void {
    this.filterProposalsOpen = false
  }

  private applyDataSelection(): void {
    const componentSelection = this.componentDetailOptions.map((option) => option.value) as number[]
    const blacklistedComponents = this.getViewableComponents(componentSelection, this.new_component_selection)
    this.process_component_selection.updateComponentValue(blacklistedComponents)

    this.getProposals()
    this.dataSelectionOpen = false
  }

  private includeAutoFill(): void {
    this.status.updateComponentValue(this.new_status)
    this.process_id.updateComponentValue(this.new_process_id)
    this.proposal_ids.updateComponentValue('')
    this.process_filters.updateComponentValue(this.proposalIndex)
    this.$emit('save')
    this.$emit('close')
  }

  private includeSelected(): void {
    const selectedProposalIdsString = this.selectedProposalIds
        .map((item) => item.toString())
        .join(',')
    this.status.updateComponentValue(this.new_status)
    this.process_id.updateComponentValue(this.new_process_id)
    this.proposal_ids.updateComponentValue(selectedProposalIdsString)
    this.process_filters.updateComponentValue(null)
    this.$emit('save')
    this.$emit('close')
  }

  private close(): void {
    this.$emit('close')
  }
}
