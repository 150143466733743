import { BaseService }                             from '@/services/base'
import { SuggestionRequest }                       from '@/requests/suggestions/SuggestionRequest'
import { ISuggestionResource, SuggestionResource } from '@/models/suggestions/SuggestionResource'
import { CommentResource, ICommentResource }       from '@/models/comments/CommentResource'
import { AxiosPromise }                            from 'axios'
import { CommentUpdateRequest }                    from '@/requests/comments/CommentUpdateRequest'
import { SuggestionUpdateRequest }                 from '@/requests/suggestions/SuggestionUpdateRequest'

export class SuggestionService extends BaseService {
  constructor() {
    super('suggestions')
  }

  /**
   * Get a single suggestion.
   *
   * @param suggestionId
   */
  public async get(suggestionId: number): Promise<DetailResponse<SuggestionResource>> {
    const { data } = await this.client.get<DetailResponse<ISuggestionResource>>(`${this.namespace}/${suggestionId}`)
    return {
      ...data,
      data: new SuggestionResource(data.data)
    }
  }

  /**
   * Delete a single suggestion
   *
   * @param suggestionId
   */
  public delete(suggestionId: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/${suggestionId}`)
  }

  /**
   * Post a new suggestion.
   *
   * @param body
   */
  public async post(body: SuggestionRequest): Promise<DetailResponse<SuggestionResource>> {
    const { data } = await this.client.post<DetailResponse<ISuggestionResource>>(`${this.namespace}`, body)
    return {
      ...data,
      data: new SuggestionResource(data.data)
    }
  }

  public async patch(suggestionId: number, suggestion: SuggestionUpdateRequest): Promise<DetailResponse<SuggestionResource>> {
    const { data } = await this.client.patch<DetailResponse<ISuggestionResource>>(`${this.namespace}/${suggestionId}`, suggestion)
    return {
      ...data,
      data: new SuggestionResource(data.data)
    }
  }

  /**
   * Get the comments for a suggestion.
   *
   * @param params
   */
  public async getComments(params?: Dictionary<any>): Promise<IndexResponse<CommentResource>> {
    return { data: []}
    // const { data } = await this.client.get(`${this.namespace}/${params?.suggestionId}/comments`)
    // return {
    //   ...data,
    //   data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    // }
  }
}
