














































































































































import { truncate } from '@/helpers'
import orderBy from 'lodash/orderBy'
import { mixin as clickaway } from '@/plugins/vue-clickaway'
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator'

import SearchInput from '@/components/inputs/Search.vue'
import MultiLayerDropdown from '@/components/inputs/dropdowns/MultiLayerDropdown.vue'

@Component({
  mixins: [clickaway],
  components: {
    SearchInput,
    MultiLayerDropdown,
  },
})
export default class DropdownInput extends Vue {
  @Model('input')
  private readonly value!: string | number | undefined | null | Array<string | number | undefined | null>

  @Prop(String)
  private readonly placeholder!: string

  @Prop(String)
  private readonly label!: string

  @Prop(String)
  private readonly description!: string

  @Prop(String)
  private readonly name!: string

  @Prop(Boolean)
  private readonly disabled!: boolean

  @Prop(Boolean)
  private readonly wide!: false

  @Prop(Array)
  private readonly errors!: ErrorResponse[]

  @Prop(Array)
  private readonly options?: SelectItem[]

  @Prop(Boolean)
  private readonly multiple!: boolean

  @Prop({ default: true })
  private readonly canDeselect!: boolean

  @Prop({ default: false })
  private readonly canSearch!: boolean

  @Prop({ default: false })
  private readonly hasSelectionAmount!: boolean

  @Prop({ default: false })
  private readonly withBadges!: boolean

  private search: string = ''

  private open: boolean = false
  private position: 'top' | 'bottom' = 'top'
  private horizontalPosition: 'right' | 'left' = 'right'

  private get transition(): 'slide-in' | 'slide-bottom' {
    return this.position === 'top' ? 'slide-in' : 'slide-bottom'
  }

  private get classes(): { [key: string]: boolean } {
    return {
      disabled: this.disabled,
      error: this.hasErrors,
      multiple: this.multiple,
      selected: this.multiple && Array.isArray(this.value) ? this.value.length > 0 : !!this.value,
      wide: this.wide,
    }
  }

  private get positionClasses(): string[] {
    const positionClasses: string[] = []

    if (this.wide) {
      positionClasses.push(this.horizontalPosition)
    }
    positionClasses.push(this.position)

    return positionClasses
  }

  private get buttonText(): string {
    return typeof this.selectedItems === 'object' && !(this.selectedItems instanceof Array)
        ? this.selectedItems.label
        : this.placeholder
  }

  private get deSelectMultipleText(): string {
    if (!Array.isArray(this.value)) return `Deselect all`
    const ITEMS = this.selectedItems as SelectItem[]
    if (this.value.length === this.options?.length) {
      return `Deselect all (${ITEMS.length})`
    } else if (this.search.length > 0) {
      return `Select all (${this.filteredOptions.length})`
    } else {
      return `Select all (${this.options?.length ?? 0})`
    }
  }

  private get flattenOptions(): SelectItem[] {
    const options: SelectItem[] = []
    if (this.options) {
      for (const option of this.options) {
        options.push(...this.getOptions(option))
      }
    }
    return options
  }

  private getOptions(option: SelectItem): SelectItem[] {

    const options: SelectItem[] = []
    options.push(option)
    if (!option.options) return options
    for (const nestedOption of option.options) {
      options.push(...this.getOptions(nestedOption))
    }
    return options
  }

  private get selectedItems(): SelectItem | SelectItem[] | undefined {
    const value = this.value
    if (this.multiple && Array.isArray(value)) {
      return this.flattenOptions.filter((item: SelectItem) => item.value && Array.isArray(value) ? value.map((val) => val?.toString().toLowerCase()).includes(item.value.toString().toLowerCase()) : false)
    } else {
      return this.flattenOptions.find((item: SelectItem) => value === item.value)
    }
  }

  private get hasErrors(): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString(): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString(): string {
    const ITEMS = this.selectedItems as SelectItem[]

    const selectableOptions = this.options?.filter((option: SelectItem) => {
      return option.count !== undefined ? option.count > 0 : true
    })

    if (this.multiple && this.hasSelectionAmount && ITEMS.length > 0) {
      return this.errorString
          ? this.errorString
          : `${this.label} (${ITEMS.length})`
    }

    return this.errorString
        ? this.errorString
        : this.multiple && this.hasSelectionAmount && (this.options?.length ?? 0) > 1
            ? `${this.label} (${selectableOptions?.length ?? 0})`
            : this.label
  }

  private get filteredOptions(): SelectItem[] {
    if (this.search) {
      return this.filterOptionsOnSearch(this.options ?? [])
    } else {
      return (this.options ?? []).some((o) => !!o.count)
          ? this.orderOnCount(this.options ?? [])
          : this.options ?? []
    }
  }

  private filterOptionsOnSearch(options: SelectItem[]): SelectItem[] {
    return options.filter((option: SelectItem) => this.filterOption(option))
  }

  private filterOption(option: SelectItem): boolean {
    return option.label.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) || (option.options?.some((subOption) => this.filterOption(subOption)) ?? false)
  }

  private orderOnCount(options: SelectItem[]): SelectItem[] {
    // Sort options by count / alphabetically, and sort sublist by label
    return orderBy(
        orderBy(options, ['count', (option) => option.label.toLowerCase().trim().trimEnd()], ['desc', 'asc']),
        [(o) => o.count ? o.label.toLowerCase().trim().trimEnd() : -1],
    )
  }

  @Watch('open')
  private onOpen(val: boolean, oldVal: boolean): void {
    if (!val && oldVal) {
      this.$emit('dropdownClosed')
      this.search = ''
    } else if (val && !oldVal) {
      this.$emit('dropdownOpened', this.name)
    }
  }

  private mounted(): void {
    this.checkViewportPosition()
    this.checkHorizontalViewportPosition()
  }

  private checkViewportPosition(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    this.position =
        wrapper.getClientRects()[0].top > window.innerHeight / 2
            ? 'bottom'
            : 'top'
  }

  private checkHorizontalViewportPosition(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    this.horizontalPosition = wrapper.getClientRects()[0].left > window.innerWidth - wrapper.getClientRects()[0].width * 1.5 ? 'left' : 'right'
  }

  // Because of object reference we dont need to return the value
  private removeRecursiveNestedOptions(value: Array<string | number | undefined | null>, option: SelectItem): void {
    if (option.options) {
      for (const nestedOption of option?.options ?? []) {
        const optionIndex = value.findIndex((val) => nestedOption.value === val)
        if (optionIndex > -1) value.splice(optionIndex, 1)
        if (nestedOption.options) {
          this.removeRecursiveNestedOptions(value, nestedOption)
        }
      }
    }
  }

  private selectItem({ option, parent }: { option: SelectItem, parent?: SelectItem }): void {
    if (this.multiple) {

      if (Array.isArray(this.value)) {
        const value = [...this.value]
        const isAlreadySelected = value.includes(option.value)

        // Just deselect current value
        if (isAlreadySelected) {
          const optionIndex = value.findIndex((val) => option.value === val)
          if (optionIndex > -1) value.splice(optionIndex, 1)

          // if not selected
        } else {

          // Deselect all children of the option because we only need to select the parent
          if ((option?.options ?? [])?.length > 0 && Array.isArray(value)) {
            this.removeRecursiveNestedOptions(value, option)
          }

          // If parent
          if (parent) {

            const nestedOptionsCount = parent.options?.length ?? 0
            const selectedNestedOptionsCount = value.filter((singleValue) => parent.options?.some((o) => o.value === singleValue)).length

            // If parent selected but we deselect an nested option
            // Deselect parent and select all nested but this one
            if (value.includes(parent.value)) {

              // Remove parent
              const parentIndex = value.findIndex((val) => parent.value === val)
              if (parentIndex > -1) value.splice(parentIndex, 1)

              // Select all children except for the we are deselecting
              for (const nestedOption of parent?.options ?? []) {
                if (nestedOption.value !== option.value) {
                  value.push(nestedOption.value)
                }
              }
              // If we have a parent check if this is the last child te be selected,
              // If so, deselect all children and select parent only
            } else if (selectedNestedOptionsCount === nestedOptionsCount - 1) {
              // push parent
              value.push(parent.value)
              if (Array.isArray(value)) {
                this.removeRecursiveNestedOptions(value, option)
              }

            } else {
              // Just select item
              value.push(option.value)
            }

            // Just select item
          } else {
            value.push(option.value)
          }
        }

        this.$emit('input', value)
        // If value is not an array we just make it an array with this value selected
        // since the value needs to be an array when the prop multiple is true
      } else {
        this.$emit('input', [option.value])
      }
    } else {
      this.$emit('input', option.value)
      this.closeDropdown()
    }
    // this.closeDropdown()
  }

  private toggleSelectAll(): void {
    if (this.disabled) {
      return
    }
    // Everything is already selected, deselect everything
    if (this.multiple && Array.isArray(this.value) && this.value.length === this.options?.length) {
      this.$emit('input', [])
      // Not everything is select so select all parents
    } else if (this.multiple && Array.isArray(this.value)) {
      if (this.search.length > 0) {
        this.$emit('input', this.filteredOptions.map(({value}) => value))
      } else {
        this.$emit('input', (this.options ?? []).map(({value}) => value))
      }
      // If multiple but value wasn't an array set to empty array instead
    } else if (this.multiple) {
      this.$emit('input', [])
    }
  }

  private deSelect(): void {
    this.$emit('input', this.multiple ? [] : null)
    this.closeDropdown()
  }

  private closeDropdown(): void {
    this.open = false
  }

  private toggleDropdown(e: any): void {
    if (this.disabled) {
      return
    }
    if (!this.open) {
      this.checkViewportPosition()
    }
    this.open = !this.open
  }

  private truncatedLabel(label: string): string {
    return truncate(label, 10)
  }

  private optionLabel(option: SelectItem): string {
    if (option.count !== undefined) {
      return `${option.label} (${option.count})`
    }

    return option.label
  }

  private multipleOptionsTooltip(options: SelectItem[]): string {
    let optionString = ''

    options.forEach(
        (option: SelectItem, index: number, array: SelectItem[]) => {
          if (index > 0) {
            if (option.count !== undefined) {
              optionString += `${option.label} (${option.count})`
            } else {
              optionString += option.label
            }
            if (index !== options.length - 1) {
              optionString += ', '
            }
          }
        },
    )

    return optionString
  }
}
