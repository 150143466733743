








































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { WidgetMixin } from '@/plugins/widget-mixin'

import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import TextInput from '@/components/inputs/Text.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import { flexibleDocumentModule } from '@/store'

@Component({
  name: 'ImageWidget',
  components: {
    TextInput,
    SmallLoader,
  },
})
export default class DataObjectWidget extends Mixins(WidgetMixin) {
  private fileTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg'
  ]

  private apiUrl = process.env.VUE_APP_BASE_URI;

  private get imageItem(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((element) => element.component_key === 'image_url')
  }

  private get altItem(): FlexibleDocumentComponentCollectionResource | undefined {
    return this.item.items.find((element) => element.component_key === 'alt_text')
  }

  private deleteImage(): void {
    if (this.imageItem) {
      this.imageItem.component_value = ''
    }
  }

  private async uploadFile(event: any): Promise<void> {
    if (!flexibleDocumentModule.flexibleDocument) return
    try {
      this.item.enableEdit()
      this.item.enableLoading()
      const request = new FormData()
      request.append('file', event.target.files[0])
      const {data} = await flexibleDocumentModule.flexibleDocument.flexibleDocumentService.uploadFile(request)
      this.imageItem?.updateComponentValue(data.image_url)
      this.item.disableLoading()
    } catch (e) {
      this.item.disableLoading()
    }
  }
}
