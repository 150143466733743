
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Metatags extends Vue {
    @Prop() public tags!: any[]
    @Prop() public type!: string
    @Prop() public simple!: boolean

    public colors: any = {
        rc: { bg: '#F2D5D4', border: '#CA5855', name: 'Reg Categories' },
        sl: { bg: '#DCEBE6', border: '#72AD9D', name: 'Service Lines' },
        ct: { bg: '#D4AFFF50', border: '#9369c6', name: 'Client Taxonomies' },
        th: { bg: '#F1F0FD', border: '#dbdbdd', name: 'Themes' },
        le: { bg: '#white', border: '#dbdbdd', name: 'Legal Entities' },
        md: { bg: '#F9EBA2', border: '#b29f3e', name: 'Mapped Documents' },
        ae: { bg: '#C4E6EC', border: '#b29f3e', name: 'Accountable Executives' },
        other: { bg: '#FFF', border: '#c6c6c6', name: ''}
    }

    public bgColor: string = this.colors.hasOwnProperty(this.type) ? this.colors[this.type].bg : this.colors.other.bg
    public borderColor: string = this.colors.hasOwnProperty(this.type) ? this.colors[this.type].border : this.colors.other.border

    public getSimpleTagNames() {
        return  this.tags.map((tag: any) => tag.name)
    }
}
