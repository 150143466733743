
















































// components
import Pagination from '@/components/paginations/Pagination.vue'

import { ProcessResource }            from '@/models/process/ProcessResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

import { Component, Model, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Pagination
  }
})
export default class ProposalsList extends Vue {

  @Prop()
  private readonly selected!: number[] | number
  @Prop()
  private readonly canSelect!: boolean
  @Prop()
  private readonly canSelectAll!: boolean
  @Prop()
  private readonly data!: ProposalCollectionResource[]
  @Prop()
  private readonly process!: ProcessResource
  @Prop({ required: false })
  private readonly pagination!: PaginationInterface
  @Prop({ default: false })
  private readonly readonly!: boolean
  @Prop({ default: null })
  private readonly editId!: number | null
  @Prop({ default: null })
  private readonly activeChildId!: number | null
  @Prop({ default: null })
  private readonly openProposalId!: number | null
  @Prop({ default: true })
  private readonly canNavigate!: boolean
  @Prop({ default: true })
  private readonly canRedirect!: boolean
  @Prop() public metaTags: any
  @Prop() public corlyticsService: any


  private isDisabled(item: ProposalCollectionResource): boolean {
    return this.editId ? this.editId !== item.id : false
  }

  // is a status selected?
  private get filteredData(): ProposalCollectionResource[] {
    return this.data
  }

  private get itemCount(): number {
    return this.data.length
  }

  private get canSelectMultiple(): boolean {
    return typeof this.selected === 'object'
  }

  // Computed
  private get canDefine(): boolean {
    return this.process ? this.process.canPerformAction('can_create_proposal') : false
  }

  private get allItemsSelected(): boolean {
    return typeof this.selected === 'object' ? this.selected.length > 0 && this.selected.length >= this.data.length : false
  }

  private isSelected(id: number): boolean {
    if (this.canSelectMultiple && typeof this.selected === 'object') {
      return this.selected.indexOf(id) > -1
    } else {
      return this.selected === id
    }
  }

  /**
   *  (De)selects an item, updates the current selected status
   *  and and communicates about the change to parent component
   */
  private setSelected(data: ProposalCollectionResource): void {
    this.$emit('selectProposal', data)
  }

  private disabledInList(item: ProposalCollectionResource): boolean {
    return this.openProposalId ? this.openProposalId !== item.id : false
  }

  private editing(val: boolean): void {
    this.$emit('editing', val)
  }
}
