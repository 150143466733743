























import {Route} from 'vue-router'
import {projectModule} from '@/store'
import {hexToRgb} from '@/helpers/colors'
import {ComponentValue} from '@/models/widgets/ComponentValue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {DocumentResource} from '@/models/documents/DocumentResource'
import {checkSelection, getSelectionContent} from '@/helpers/textSelector'

type ClonedRoute = Route

@Component
export default class DocumentViewer extends Vue {
  // Props
  @Prop({required: true})
  private document!: DetailResponse<DocumentResource>

  @Prop({required: false, default: false})
  private fontBigger!: boolean

  @Prop({required: false, default: false})
  private showChanges!: boolean

  @Prop()
  private markingId!: number | null

  @Prop({default: false})
  private markerEnabled!: boolean

  @Prop({default: ''})
  private annotationButtonText!: string

  @Prop({default: null})
  private markedData!: ComponentValue | null

  private annotationPicker: { position: undefined | { top: number, left: number }, open: boolean, mouseEvent: MouseEvent | undefined } = {
    position: undefined,
    open: false,
    mouseEvent: undefined,
  }

  private get taggingProcess() {
    return projectModule.detail?.taggingProcess
  }

  @Watch('$route')
  private onRouteChange(val: ClonedRoute): void {
    if (val.name === 'projects-detail-document-detail') {
      this.modifyActiveDomProposalStatus(true, parseInt(val.params.proposal_id, 10))
    } else {
      this.modifyActiveDomProposalStatus(false)
    }
  }

  private modifyActiveDomProposalStatus(activate: boolean, proposalId?: number | undefined): void {
    const wrapperDiv = document.getElementById('documentContainer')

    if (activate) {
      if (wrapperDiv) wrapperDiv.classList.add('inactive')
      const proposalSpans = document.querySelectorAll(`span[data-proposal-id="${proposalId}"]`)
      const activeSpans = document.querySelectorAll(`span.active`)

      for (const span of activeSpans) {
        span.classList.remove('active')
      }

      for (const span of proposalSpans) {
        span.classList.add('active')
      }
    } else {
      if (wrapperDiv) wrapperDiv.classList.remove('inactive')
      const activeSpans = document.querySelectorAll(`span.active`)

      for (const span of activeSpans) {
        span.classList.remove('active')
      }
    }
  }

  private get annotationPickerPosition(): any {
    if (this.annotationPicker.position) {
      return {
        top: `${this.annotationPicker.position.top - 30}px`,
        left: `${this.annotationPicker.position.left + 20}px`,
      }
    }
    return false
  }

  private mounted(): void {
    this.setMouseHandler()
    this.setTaggingProcess()
  }

  private setTaggingProcess(): void {
    if (this.taggingProcess) {

      // Dynamically set styles for statuses
      if (this.taggingProcess?.statuses) {
        let styleString = '<style>'
        for (const tagStatus of this.taggingProcess?.statuses) {
          styleString += ` span.content-piece.proposal.${tagStatus.value} { background-color: rgba(${hexToRgb(tagStatus.color)}, .2) }`
          styleString += ` span.content-piece.proposal.${tagStatus.value}.active { background-color: rgba(${hexToRgb(tagStatus.color)}, .35) }`
          styleString += ` span.content-piece.proposal.${tagStatus.value}.hover { background-color: rgba(${hexToRgb(tagStatus.color)}, .35) }`
        }
        styleString += '</style>'

        const stylescontainer = this.$refs.stylescontainer as HTMLElement
        stylescontainer.innerHTML = styleString
      }
    }
  }

  private setMouseHandler(): void {
    const container = this.$refs.container as HTMLElement
    container.addEventListener('mouseup', this.onCheck)
    container.addEventListener('copy', this.formatCopiedText)
  }

  private formatCopiedText(e: ClipboardEvent): void {
    const S = window.getSelection()
    // Set the selection copy text
    if (S && S.toString().length > 0) {
      const selectionText = getSelectionContent(S)

      e?.clipboardData?.setData('text', selectionText)
      e?.preventDefault()
    }
  }

  private onCheck(e: MouseEvent): void {
    checkSelection(this.onStart, this.onStop, this.onUpdate)

    if (this.markerEnabled) {
      const S = window.getSelection()
      if (S && S.toString().length > 0) {
        this.openAnnotationPicker(e)
      } else {
        this.closeAnnotationPicker()
      }
    } else {
      this.closeAnnotationPicker()
    }
  }

  private onStart(): void {
    this.$emit('startSelection')
  }

  private onStop(): void {
    this.$emit('stopSelection')
  }

  private onUpdate(data: { data: string, start_id: number, end_id: number }): void {
    this.$emit('updateSelection', data)
  }

  private onSubmit(): void {
    this.$emit('submitSelection')
    this.closeAnnotationPicker()
  }

  private openAnnotationPicker(e: MouseEvent): void {
    // Reset annotation picker to new values to set position and selection range
    this.annotationPicker.mouseEvent = e
    this.annotationPicker.position = this.getMousePosition(
        this.annotationPicker.mouseEvent,
    )
    this.annotationPicker.open = true
  }

  private closeAnnotationPicker(): void {
    // Reset annotation picker to default values
    this.annotationPicker.position = undefined
    this.annotationPicker.open = false
    this.annotationPicker.mouseEvent = undefined
  }

  private getMousePosition(e: MouseEvent): { top: number, left: number } {
    // @ts-ignore - Supported in modern browsers
    const pageX = e.clientX
    // @ts-ignore
    const pageY = e.clientY

    return {
      top: pageY,
      left: pageX,
    }
  }
}
