import { BaseService } from '@/services/base'

import { ProcessResource, IProcessResource }                                     from '@/models/process/ProcessResource'
import { ProposalResource, IProposalResource }                                   from '@/models/proposals/ProposalResource'
import { StatusResourceCollection, IStatusResourceCollection }                   from '@/models/status/StatusResourceCollection'
import { ProposalCollectionResource, IProposalCollectionResource }               from '@/models/proposals/ProposalCollectionResource'
import { ProposalSummaryCollectionResource, IProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'

import { ProposalIndexRequest }                            from '@/requests/proposals/ProposalIndexRequest'
import { ProposalCreateRequest }                           from '@/requests/proposals/ProposalCreateRequest'
import { ProposalSimilarityRequest }                       from '@/requests/proposals/ProposalSimilarityRequest'
import { LinkedProposalIndexRequest }                      from '@/requests/proposals/LinkedProposalIndexRequest'
import { ProposalSuggestionCheckRequest }                  from '@/requests/proposals/ProposalSuggestionCheckRequest'
import { DocumentSimpleResource, IDocumentSimpleResource } from '@/models/documents/DocumentSimpleResource'
import { ProposalAllocateUsersRequest }                    from '@/requests/proposals/ProposalAllocateUsersRequest'
import { ProposalStatusPatchRequest }                      from '@/requests/proposals/ProposalStatusPatchRequest'
import { ProposalsAllocateUsersRequest }                   from '@/requests/proposals/multiple/ProposalsAllocateUsersRequest'
import { ProposalsStatusPatchRequest }                     from '@/requests/proposals/multiple/ProposalsStatusPatchRequest'
import { ProposalsLockRequest }                            from '@/requests/proposals/multiple/ProposalsLockRequest'
import { ProposalsDeleteRequest }                          from '@/requests/proposals/multiple/ProposalsDeleteRequest'

export class ProcessService extends BaseService {

  public project_id: number
  public process_id: number

  constructor({ project_id, process_id }: { project_id: number, process_id: number }) {
    super(`projects/${project_id}/processes/${process_id}`)
    this.project_id = project_id
    this.process_id = process_id
  }

  public async get(): Promise<DetailResponse<ProcessResource>> {
    const { data } = await this.client.get<DetailResponse<IProcessResource>>(`${this.namespace}`)
    return {
      ...data,
      data: new ProcessResource(data.data)
    }
  }

  public async getProposals(params?: ProposalIndexRequest | LinkedProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals`, { params })
    return {
      ...data,
      data: data.data.map((proposal: IProposalCollectionResource) => new ProposalCollectionResource(proposal))
    }
  }

  public async getDocumentProposals(documentId: number, params?: ProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalCollectionResource>>(`projects/${this.project_id}/documents/${documentId}/proposals`, { params })
    return {
      ...data,
      data: data.data.map((proposal: IProposalCollectionResource) => new ProposalCollectionResource(proposal))
    }
  }

  public async getSummaryProposals(params?: ProposalIndexRequest): Promise<IndexResponse<ProposalSummaryCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalSummaryCollectionResource>>(`${this.namespace}/proposals/index`, { params })
    
    return {
      ...data,
      data: data.data.map((proposalSummary: IProposalSummaryCollectionResource) => new ProposalSummaryCollectionResource(proposalSummary))
    }
  }

  public async getLinkedIncludedProposals(connected_process_id: number, body: LinkedProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals/linked/${connected_process_id}/included`, {
      params: body
    })
    return {
      ...data,
      data: data.data.map((proposal: IProposalCollectionResource) => new ProposalCollectionResource(proposal))
    }
  }

  public async getLinkedProposals({ connected_process_id, connected_status, connected_proposal_ids, component_ids, connected_component_ids }: LinkedProposalIndexRequest): Promise<IndexResponse<ProposalCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals/linked/${connected_process_id}`, {
      params: {
        connected_status,
        connected_proposal_ids,
        component_ids,
        connected_component_ids
      }
    })
    return {
      ...data,
      data: data.data.map((proposalSummary: IProposalCollectionResource) => new ProposalCollectionResource(proposalSummary))
    }
  }

  public async getAvailableProposals(params?: ProposalIndexRequest): Promise<IndexResponse<ProposalSummaryCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IProposalSummaryCollectionResource>>(`${this.namespace}/proposals/available`, { params })
    return {
      ...data,
      data: data.data.map((proposal: IProposalSummaryCollectionResource) => new ProposalSummaryCollectionResource(proposal))
    }
  }

  public async checkSimilarity(body: ProposalSimilarityRequest): Promise<{ proposal: ProposalCollectionResource, similarity: ProposalCollectionResource[] }> {
    const { data } = await this.client.post<{ proposal: IProposalCollectionResource, similarity: IProposalCollectionResource[] }>(`${this.namespace}/proposals/check-similarity`, body)
    return {
      proposal: new ProposalCollectionResource(data.proposal),
      similarity: data.similarity.map((proposal: IProposalCollectionResource) => new ProposalCollectionResource(proposal))
    }
  }

  public async createProposal(body: ProposalCreateRequest) {
    const { data } = await this.client.post<DetailResponse<IProposalResource>>(`${this.namespace}/proposals`, body)
    return {
      ...data,
      data: new ProposalResource(data.data)
    }
  }

  public async createProposals(body: ProposalCreateRequest) {
    const { data } = await this.client.post<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals/multiple`, body)
    return {
      ...data,
      data: data.data.map((p) => new ProposalCollectionResource(p))
    }
  }

  public async getProposal(proposal_id: number, similarity_id?: number): Promise<DetailResponse<ProposalResource>> {
    const { data } = await this.client.get<DetailResponse<IProposalResource>>(`${this.namespace}/proposals/${proposal_id}`, { params: { similarity_id } })
    return {
      ...data,
      data: new ProposalResource(data.data, similarity_id)
    }
  }

  public async getStatuses(): Promise<IndexResponse<StatusResourceCollection>> {
    const { data } = await this.client.get<IndexResponse<IStatusResourceCollection>>(`${this.namespace}/statuses`)
    return {
      ...data,
      data: data.data.map((status: IStatusResourceCollection) => new StatusResourceCollection(status))
    }
  }

  public async getAvailableDocuments() {
    const { data } = await this.client.get<IndexResponse<IDocumentSimpleResource>>(`${this.namespace}/documents/available`)
    return {
      ...data,
      data: data.data.map((d) => new DocumentSimpleResource(d))
    }
  }

  // Multi select
  public async allocateUsersToProposals(body: ProposalsAllocateUsersRequest) {
    const { data } = await this.client.patch<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals/multiple/users`, body)
    return data
  }

  public async patchStatusOfProposals(body: ProposalsStatusPatchRequest) {
    const { data } = await this.client.patch<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals`, body)
    return data
  }

  public async toggleLockOfProposals(body: ProposalsLockRequest) {
    const { data } = await this.client.patch<IndexResponse<IProposalCollectionResource>>(`${this.namespace}/proposals/multiple/lock`, body)
    return data
  }

  public async deleteProposals(body: ProposalsDeleteRequest): Promise<void> {
    await this.client.delete<void>(`${this.namespace}/proposals/multiple`, { params: body })
    return
  }
}

