































import { flexibleDocumentModule, stateModule } from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mixin as clickaway }   from '@/plugins/vue-clickaway'
import { ComponentExists }      from '@/plugins/component-exists'

import { FlexibleDocumentResource }                  from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentDropdownOptionResource }    from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'
import { FlexibleDocumentWidgetCollectionResource }  from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'
import { FlexibleDocumentChapterCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentChapterCollectionResource'
import { FlexibleDocumentSectionCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentSectionCollectionResource'

import Search from '@/components/inputs/Search.vue'

@Component({
  name: 'NewItemDropdown',
  components: {
    Search
  },
  mixins: [clickaway, ComponentExists]
})
export default class NewItemDropdown extends Vue {

  @Prop({ default: 'Add a component' })
  private title!: string

  @Prop({ required: true })
  private anchor_uuid!: string

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private dropdownOptions!: FlexibleDocumentDropdownOptionResource[]

  @Prop({ required: true })
  private parent!: FlexibleDocumentChapterCollectionResource | FlexibleDocumentSectionCollectionResource | FlexibleDocumentResource

  @Prop({ required: false, default: 0 })
  private depth!: number

  @Prop({ default: true })
  private hasSearch!: boolean

  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: false })
  private stayVisible!: boolean

  @Prop({ default: false })
  private inlineButton!: boolean

  private search = ''
  private dropdownOpen = false
  private position: 'top' | 'bottom' = 'top'

  private toggleDropdown(): void {
    this.checkViewportPosition()
    this.dropdownOpen = !this.dropdownOpen
    this.search = ''
  }

  private closeDropdown(): void {
    this.dropdownOpen = false
    this.search = ''
  }

  private get isNestedSectionType(): boolean {
    return (this.parent instanceof FlexibleDocumentSectionCollectionResource) && this.parent.type === 'Section'
  }

  private async addItem(item: FlexibleDocumentDropdownOptionResource): Promise<void> {
    stateModule.setLoading(true)
    let index = this.index
    if (item.widget_type === 'Footer') {
      index = this.parent.items.length
    }

    let data: any = null
    // Enable adding a section through another section in the parent chapter
    if (this.parent instanceof FlexibleDocumentSectionCollectionResource && this.isNestedSectionType && item.type === 'Section') {
      data = await this.parent.addItem({ anchor_uuid: this.parent.anchor_uuid, ...item }, -1, this.$route.params.template_id)
      this.$emit('refreshDocument')
    } else {
      data = await this.parent.addItem({ anchor_uuid: this.parent.uuid, ...item }, index, this.$route.params.template_id)
    }

    if (data) {
      this.$router.push({
        name: 'projects-flexible-document-element',
        params: { element_id: data.data.uuid },
        query: { editing: data.data.uuid }
      })
    }
    stateModule.setLoading(false)
    this.closeDropdown()
  }

  private get filteredOptions(): FlexibleDocumentDropdownOptionResource[] {
    return this.dropdownOptions
        .filter((item) => this.filterItemOnDepth(item))
        .filter((item) => this.filterSpecificWidgetTypeOnDepth(item))
        .filter((item) => this.search !== '' ? JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()) : true)
        .sort((a, b) => this.sortOptions(a, b))
  }

  private sortOptions(a: FlexibleDocumentDropdownOptionResource, b: FlexibleDocumentDropdownOptionResource): number {
    return a.widget_type === 'Footer' ? 1 : 0
  }
  private filterSpecificWidgetTypeOnDepth(item: FlexibleDocumentDropdownOptionResource): boolean {
    if (item.widget_type === 'Footer') {
      return this.depth === 1
    } else {
      return true
    }
  }

  private filterItemOnDepth(item: FlexibleDocumentDropdownOptionResource): boolean {
    switch (item.type) {
      case 'TitlePage':
        return this.depth === 0
      case 'Chapter':
        return this.depth === 0
      case 'Section':
        return this.depth > 0
      case 'Component':
        return this.depth > 0
      case 'Widget':
        return this.depth > 0
    }
  }

  private checkViewportPosition(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    this.position = wrapper.getClientRects()[0].top > (window.innerHeight / 2) ? 'bottom' : 'top'
  }
}
