export default {
  overview: {
    header: {
      title: 'My Projects',
      actions: {
        calendar: 'Calendar',
        new: 'Start new project',
      },
    },
    filters: {
      all: 'All ({count})',
      status: '{status} ({count})',
    },
    empty: 'There are no projects found.',
    recent: {
      title: 'Recent projects',
      new: 'Start new project',
    },
    table: {
      headers: {
        name: 'Name',
        type: 'Type',
        updated_at: 'Last update',
        end_date: 'Project deadline',
        created_at: 'Creation date',
        owner: 'Initiator',
        status: 'Project status',
        project_type: 'Project type',
        modified: 'Modified',
        modified_by: 'Modified by',
        document_status: 'Status'
      },
    },
  },
  new: {
    documents: {
      header: {
        title: 'Start new project',
        actions: {
          create: 'Add team members',
        },
      },
      finder: {
        title: 'Documents',
        search: {
          placeholder: 'Search documents...',
        },
      },
      upload: {
        label: 'Selected documents',
        placeholder: '<b>Upload</b> or <b>select</b> document(s)',
      },
      input: {
        label: 'Project name',
        placeholder: 'Prefilled based on selected document',
      },
    },
    members: {
      header: {
        title: 'Start new project',
        actions: {
          create: 'Create project',
        },
      },
      finder: {
        title: 'Available members',
        search: {
          placeholder: 'Search available members...',
        },
      },
      upload: {
        label: 'Selected documents',
        placeholder: '<b>Upload</b> or <b>select</b> document(s)',
      },
      input: {
        label: 'Project name',
        placeholder: 'Prefilled based on selected document',
      },
    },
  },
  detail: {
    navigation: {
      project_name: 'Project name',
      title: 'Quick nav',
      items: {
        'projects-single-detail': 'Dashboard',
        'projects-single-detail-documents': 'Documents',
        'projects-detail-document-detail': 'Documents',
        'projects-single-detail-connections': 'Dashboard',
        'projects-single-detail-team': 'Dashboard',
        'projects-detail-document': 'Documents',
        'projects-detail-document-proposals': 'Documents',
        'projects-detail-document-proposal-multi-select': 'Documents',
        'projects-detail-document-proposal-detail': 'Documents',
        'projects-flexible-document': 'Flexible document',
        'projects-flexible-document-proposal': 'Flexible document',
        'projects-flexible-document-element': 'Flexible document',
        'projects-flexible-document-multi-select': 'Flexible document'
      },
      back: 'Back to projects',
    },
    pages: {
      index: {
        statusTypes : {
          defined : 'Defined',
          approved : 'Approved',
          declined : 'Declined',
          submitted : 'Submitted',
        },
        tiles: {
          proposals: {
            title: '{processName}s',
            lower_title: '${processName}',
            button: 'View ${processName}',
            button_admin: 'Create / edit {processName}',
          },
          policy: {
            title: 'Policy',
            lower_title: 'policy',
            button: 'View policy',
            button_admin: 'Create / edit policy',
          },
        },
      },
      document: {
        select_document: 'Documents',
      },
      proposal: {
        title: '{processName}',
        create: 'Create new {processName}',
        remove: 'Delete {processName}',
        define: 'Define {processName}',
        submit_button: 'Submit {processName}',
        submit: 'Submit {processName} for approval',
        approve: 'Approve {processName}',
        disapprove: 'Disapprove {processName}',
        decline: 'Decline {processName}',
        release: 'Release {processName}',
        redefine: 'Define {processName}',
        reuse: 'Re-use {processName}',
        completed: 'Requirements completed',
        connect_parents: 'Connect fragments',
        connected_parents: 'Connected fragments',
        define_children: 'Define {processName}',
        select_parents: 'Please select fragments',
        no_parents: 'No fragments to select from',
        save_my: 'Save my fragment',
        no_parents_status: 'No fragments with this status',
        definer_empty_state: 'There are no {processName} created yet.',
        filtered_empty_state: 'There are no {processName} with this status.',
        definer_button: 'Create {processName}',
        admin_empty_state: 'There are no {processName} yet. Please come back later.',
        created: {
          title: '{processName} succesfully saved',
          message: '{processName} saved'
        },
        detail: {
          nav: {
            Comments: 'Comments',
            Suggestions: 'Suggestions',
            Activity: 'Activity',
            Connections: 'Connections',
            DirectConnections: 'Connections',
            Audit: 'Activity'
          }
        },
        multi_select: {
          selected: 'selected: <b>{count} {processName}</b>',
          select: 'Select all',
          deselect: 'Deselect all',
        },
        elements: {
          document_type: {
            label: 'Document type',
            placeholder: '',
          },
          publish_date: {
            label: 'Publish date',
            placeholder: '',
          }
        }
      },
      policy: {
        sections: {
          risks: {
            title: 'Risk & control objectives',
          },
          controls: {
            title: 'Controls',
          },
        },
      },
    },
  },
}
