import Vue                                      from 'vue'
import { DocumentsService }                     from '@/services/documents'
import { OrganisationDocumentsService }         from '@/services/organisationDocuments'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import * as Mutations                           from './mutation-types'

import { DocumentIndexRequest }        from '@/requests/documents/DocumentIndexRequest'
import { DocumentPresetCreateRequest } from '@/requests/documents/DocumentPresetCreateRequest'
import { DocumentCreateRequest }       from '@/requests/documents/DocumentCreateRequest'

import { DocumentCollectionResource }                      from '@/models/documents/DocumentCollectionResource'
import { DocumentPresetResource }                          from '@/models/documents/DocumentPresetResource'
import { DocumentFilterResource, IDocumentFilterResource } from '@/models/documents/DocumentFilterResource'
import { DocumentResource }                                from '@/models/documents/DocumentResource'
import { AxiosResponse }                                   from 'axios'
import { ProposalCollectionLightResource }                 from '@/models/proposals/ProposalCollectionLightResource'
import { DocumentExportByPresetRequest }                   from '@/models/documents/DocumentExportByPresetRequest'

const documentsService = new DocumentsService()
const organisationService = new OrganisationDocumentsService()

@Module({
  namespaced: true,
  name: 'documents'
})
class DocumentsModule extends VuexModule {

  // State
  private _filters: DocumentFilterResource[] | null = null
  private _presets: DocumentPresetResource[] | null = null
  private _index: IndexResponse<DocumentCollectionResource> | null = null
  private _detail: DocumentResource | null = null
  private _bookmarked: boolean = false
  private _selectedFilters: DocumentIndexRequest = {}
  private _selectedDocuments: DocumentCollectionResource[] = []
  private _selectedPreset: number | null = null
  private _markingId: number | null = null
  private _params: IndexParameters = {
    column: 'publish_date',
    dir: 'desc',
    search: '',
    page: '1',
    bookmarked: 'false',
    per_page: '20',
  }

  // Getters
  public get index(): IndexResponse<DocumentCollectionResource> | null {
    return this._index
  }

  public get params(): IndexParameters {
    return this._params
  }

  public get selectedFilters(): DocumentIndexRequest {
    return this._selectedFilters
  }

  public get query(): DocumentIndexRequest & IndexParameters {
    // Presets can be found by ID
    if (this._selectedPreset) {
      return { ...this._params, ...this._selectedFilters, ...{ presets: this._selectedPreset.toString(), search: this._selectedFilters.search }, bookmarked: this.bookmarked.toString() }
    }

    return { ...this._params, ...this._selectedFilters, bookmarked: this.bookmarked.toString() }
  }

  public get filters(): DocumentFilterResource[] | null {
    return this._filters
  }

  public get bookmarked(): boolean {
    return this._bookmarked
  }

  public get presets(): DocumentPresetResource[] | null {
    return this._presets
  }

  public get detail(): DocumentResource | null {
    return this._detail
  }

  public get selectedDocuments(): DocumentCollectionResource[] {
    return this._selectedDocuments
  }

  public get selectedPreset(): number | null {
    return this._selectedPreset
  }

  public get markingId(): number | null {
    return this._markingId
  }

  public get isMarking(): boolean {
    return !!this._markingId
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENTS })
  public async get(query?: DocumentIndexRequest): Promise<IndexResponse<any>> {
    try {
      return await documentsService.get({ ...this.query, ...query })
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENTS })
  public removeIndex(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_FILTERS })
  public removeFilters(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT })
  public async getById(id: number): Promise<any> {
    try {
      const { data } = await documentsService.getById(id)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT })
  public async post(form: DocumentCreateRequest): Promise<DetailResponse<DocumentResource>> {
    try {
      return await documentsService.post(form)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SELECT_DOCUMENT })
  public selectDocument(document: DocumentCollectionResource): DocumentCollectionResource {
    return document
  }

  @Action({ rawError: true, commit: Mutations.TOGGLE_SELECTED_DOCUMENTS })
  public toggleSelectedDocuments(): DocumentCollectionResource[] | undefined {
    return this.index?.data
  }

  @Action({ rawError: true, commit: Mutations.RESET_SELECTED_DOCUMENTS })
  public resetSelectedDocuments(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT_READ })
  public setDocumentAsRead(document_id: number): number {
    return document_id
  }

  @Action({ rawError: true, commit: Mutations.SET_DOCUMENT })
  public removeSingle(): void {
    return
  }

  // PRESETS
  @Action({ rawError: true, commit: Mutations.SET_PRESETS })
  public async getPresets(): Promise<DocumentPresetResource[]> {
    
    try {
      const { data } = await organisationService.presets()
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.ADD_PRESET })
  public async postPreset(preset: DocumentPresetCreateRequest): Promise<DocumentPresetResource> {
    try {
      const { data } = await organisationService.postPreset(preset)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.DELETE_PRESET })
  public async deletePreset({ preset }: { preset: DocumentPresetResource }): Promise<DocumentPresetResource> {
    return preset
  }

  @Action({ rawError: true, commit: Mutations.SET_PRESET })
  public setPreset(key?: number): number | undefined {
    return key
  }

  // FILTERS
  @Action({ rawError: true })
  public async getFilters(query: DocumentIndexRequest): Promise<DocumentFilterResource[]> {
    try {
      const { data } = await documentsService.filters()
      this.context.commit(Mutations.SET_FILTERS, data)
      this.context.commit(Mutations.SET_INIT_PARAMS, query)
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async updateFilters(key?: string): Promise<void> {
    try {
      const { data } = await documentsService.searchedFilters({ ...this.query }, key)
      this.context.commit(Mutations.UPDATE_FILTERS, data)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true, commit: Mutations.SET_FILTER })
  public setFilter({ key, value }: { key: string, value: string | number | Array<string | number> }): { key: string, value: string | number | Array<string | number> } {
    return { key, value }
  }

  @Action({ rawError: true, commit: Mutations.SET_BOOKMARKED })
  public setBookmarked(payload: boolean): boolean {
    return payload
  }

  @Action({ rawError: true })
  public setOrder({ col, dir }: { col: string, dir: string }): void {
    this.context.commit(Mutations.SET_PARAM, { key: 'column', value: col })
    this.context.commit(Mutations.SET_PARAM, { key: 'dir', value: dir })
  }

  @Action({ rawError: true, commit: Mutations.SET_PARAM })
  public setParam({ key, value }: { key: string, value: string | number | Array<string | number> }): { key: string, value: string | number | Array<string | number> } {
    return { key, value }
  }

  @Action({ rawError: true, commit: Mutations.RESET_FILTERS })
  public resetFilter(): void {
    return
  }

  @Action({ rawError: true, commit: Mutations.SET_MARKING_ID })
  public async startMarking(id?: number): Promise<number | undefined> {
    return id
  }

  @Action({ rawError: true, commit: Mutations.RESET_MARKING_ID })
  public async resetMarking(): Promise<null> {
    return null
  }

  @Action({ rawError: true })
  public async exportLibraryContents(query: DocumentIndexRequest): Promise<AxiosResponse> {
    try {
      return await documentsService.exportLibraryContents(query)
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async exportLibraryByPresets(body: DocumentExportByPresetRequest): Promise<AxiosResponse> {
    try {
      return await documentsService.exportLibraryByPresets(body)
    } catch (e) {
      throw e
    }
  }

  @Mutation
  private [Mutations.SET_DOCUMENT_READ](document_id: number): void {
    const document = this._index?.data.find((item) => item.id === document_id) ?? null
    if (document) {
      document.newDoc = false
    }
  }

  @Mutation
  private [Mutations.SET_ORDER]({ col, dir }: { col: string, dir: string }): void {
    this._params.column = col
    this._params.dir = dir
    this._params.page = '1'
  }

  @Mutation
  private [Mutations.SET_INIT_PARAMS](query: DocumentIndexRequest): void {
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const VALUE = query[key]
        if (key === 'presets') {
          const preset = query[key]
          if (typeof preset === 'string') {
            Vue.set(this, '_selectedPreset', parseInt(preset, 10))
          }
        } else if (key === 'bookmarked') {
          this._bookmarked = query[key] === 'true'
        } else if (key in this._params) {
          Vue.set(this._params, key, typeof VALUE === 'object' && VALUE ? VALUE[0] : VALUE)
        } else {
          const FILTER = this._filters ? this._filters.find((filter: DocumentFilterResource) => filter.key === key) : undefined
          if (FILTER) {
            if (FILTER.multiple) {
              if (typeof VALUE === 'object') {
                Vue.set(this._selectedFilters, key, [...VALUE])
              } else {
                if (VALUE && VALUE.indexOf(',') > -1) {
                  const VALUES = VALUE.split(',')
                  VALUES.forEach((value: string) => decodeURIComponent(value))
                  Vue.set(this._selectedFilters, key, [...VALUES])
                } else {
                  Vue.set(this._selectedFilters, key, [VALUE])
                }
              }
            } else {
              // Value can still be an array even if you can only select .
              // E.G. the user can change the url manually
              // So only pick the first value when this happens
              Vue.set(this._selectedFilters, key, typeof VALUE === 'object' ? VALUE[0] : VALUE)
            }
          } else {
            this._selectedFilters[key] = query[key]
          }
        }
      }
    }
  }

  // FILTERS
  @Mutation
  private [Mutations.SET_FILTER]({ key, value }: { key: string, value: string | number | Array<string | number> }): void {
    Vue.set(this._selectedFilters, key, value)
  }

  @Mutation
  private [Mutations.SET_BOOKMARKED](payload: boolean): void {
    this._bookmarked = payload
  }

  @Mutation
  private [Mutations.RESET_FILTERS](): void {
    if (this._filters) {
      for (const FILTER of this._filters) {
        Vue.set(this._selectedFilters, FILTER.key, FILTER.multiple ? [] : '')
      }
    }
  }

  @Mutation
  private [Mutations.SET_PARAM]({ key, value }: { key: string, value: string | number | Array<string | number> }): void {
    Vue.set(this._params, key, value)
  }

  // DOCUMENTS
  @Mutation
  private [Mutations.SET_DOCUMENTS](payload?: IndexResponse<any>): void {
    if (payload) {
      payload.data = payload.data.map((document: any) => new DocumentCollectionResource(document))
      this._index = payload
    } else {
      this._index = null
    }
  }

  @Mutation
  private [Mutations.SET_DOCUMENT](payload?: any): void {
    this._detail = payload ? new DocumentResource(payload) : null
  }

  @Mutation
  private [Mutations.SELECT_DOCUMENT](payload: DocumentCollectionResource): void {
    const documentIndex = this._selectedDocuments.findIndex((d) => d.id === payload.id)
    if (documentIndex > -1) {
      this._selectedDocuments.splice(documentIndex, 1)
    } else {
      this._selectedDocuments.push(payload)
    }
  }

  @Mutation
  private [Mutations.RESET_SELECTED_DOCUMENTS](): void {
    this._selectedDocuments.splice(0, this._selectedDocuments.length)
  }

  @Mutation
  private [Mutations.TOGGLE_SELECTED_DOCUMENTS](payload?: DocumentCollectionResource[]): void {
    if (payload) {
      const CURRENT_DOCUMENT_IDS = payload.map(({id}) => id)
      // All documents are already selected, So we deselect all CURRENT documents
      if (payload.every((document) => this._selectedDocuments.some((selectedDocument) => selectedDocument.id === document.id))) {
        this._selectedDocuments = this._selectedDocuments.filter((selectedDocument) => !CURRENT_DOCUMENT_IDS.includes(selectedDocument.id))
        // Select all current proposals
      } else {
        const REMAINING_DOCUMENTS = payload.filter(({id}) => !this._selectedDocuments.some((selectedDocument) => selectedDocument.id === id))
        this._selectedDocuments.push(...REMAINING_DOCUMENTS)
      }
    }
  }

  // FILTERS
  @Mutation
  private [Mutations.SET_FILTERS](payload?: DocumentFilterResource[]): void {
    this._filters = payload || null
    if (payload) {
      for (const FILTER of payload) {
        Vue.set(this._selectedFilters, FILTER.key, FILTER.multiple ? [] : '')
      }
    }
  }

  @Mutation
  private [Mutations.UPDATE_FILTERS](payload?: DocumentFilterResource[]): void {
    this._filters = payload || null
  }

  // PRESETS
  @Mutation
  private [Mutations.SET_PRESETS](payload?: DocumentPresetResource[]): void {
    this._presets = payload || null
  }

  @Mutation
  private [Mutations.SET_PRESET](payload?: number): void {
    if (payload) {
      this._selectedPreset = payload === this._selectedPreset ? null : payload

      // Set filters with preset
      const PRESET = this._presets ? this._presets.find((preset: DocumentPresetResource) => preset.id === payload) : undefined
      if (PRESET && this._selectedPreset) {
        for (const FILTER of PRESET.values) {
          Vue.set(this._selectedFilters, FILTER.key, FILTER.values)
        }
      }
    } else {
      this._selectedPreset = null
    }
  }

  @Mutation
  private [Mutations.ADD_PRESET](payload: DocumentPresetResource): void {
    if (this._presets) {
      this._presets.push(payload)
    } else {
      this._presets = [payload]
    }
  }

  @Mutation
  private [Mutations.DELETE_PRESET](payload: DocumentPresetResource): void {
    if (this._presets) {
      const ARRAY = this._presets.filter((preset: DocumentPresetResource) => preset.id !== payload.id)
      Vue.set(this, '_presets', ARRAY)
    }
  }

  @Mutation
  private [Mutations.SET_MARKING_ID](payload?: number): void {
    this._markingId = payload || null
  }

  @Mutation
  private [Mutations.RESET_MARKING_ID](): void {
    this._markingId = null
  }

}

export default DocumentsModule
