import { createSocket } from '@/api'
import { flexibleDocumentModule }      from '@/store'
import { canPerformAction }                  from '@/helpers/canPerformAction'
import { WidgetType }                        from './FlexibleDocumentWidgetType'
import { isIFlexibleDocumentWidgetResource } from '@/helpers/FlexibleDocument'

import { ProposalCollectionResource }             from '@/models/proposals/ProposalCollectionResource'
import { FlexibleDocumentItemCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import { SuggestionsResource }                    from '@/models/suggestions/SuggestionsResource'
import { ProjectUserResource }                    from '@/models/projects/ProjectUserResource'
import {
  FlexibleDocumentComponentCollectionResource,
  IFlexibleDocumentComponentCollectionResource
}                                                 from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import { SuggestionRequest } from '@/requests/suggestions/SuggestionRequest'
import { ELEMENT_EVENTS }    from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { Socket }            from 'socket.io-client'


type FlexibleDocumentWidgetCollectionPermissions = 'can_release' | 'can_redefine' | 'can_comment' | 'can_edit' | 'can_suggest' | 'can_delete' | string

export interface IFlexibleDocumentWidgetCollectionResource extends FlexibleDocumentItemCollectionResource {
  label: string
  widget_type: WidgetType
  items: IFlexibleDocumentComponentCollectionResource[]

  permissions: FlexibleDocumentWidgetCollectionPermissions[]
  icon?: string,
  channel: string
}

export class FlexibleDocumentWidgetCollectionResource extends FlexibleDocumentItemCollectionResource {
  public label: string
  public widget_type: WidgetType
  public items: FlexibleDocumentComponentCollectionResource[]
  public permissions: FlexibleDocumentWidgetCollectionPermissions[]
  public icon?: string

  public loading: boolean = false
  public channel: string

  public socket?: Socket
  public suggestions: SuggestionsResource

  // Data widgets
  public proposals: ProposalCollectionResource[] = []

  constructor({ label, items, permissions, widget_type, icon, channel, ...chapter }: IFlexibleDocumentWidgetCollectionResource, project_id: number) {
    super(chapter, project_id)
    this.label = label
    this.widget_type = widget_type
    this.items = items?.map((item) => new FlexibleDocumentComponentCollectionResource(item, project_id))
    this.permissions = permissions
    this.icon = icon

    this.channel = channel
    this.suggestions = new SuggestionsResource({
      id: chapter.uuid,
      suggestableType: 'document_widget',
      service: this.service,
      project_id: this.project_id
    })

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public canPerformAction(key: FlexibleDocumentWidgetCollectionPermissions): boolean {
    return canPerformAction<FlexibleDocumentWidgetCollectionPermissions>(this.permissions, key)
  }

  public get heading(): string {
    return this.title || this.label
  }

  public enableEdit(): void {
    this.revertComponents()
  }

  public disableEdit(): void {
    this.revertComponents()
  }

  public enableSuggest(): void {
    this.revertComponents()
  }

  public disableSuggest(): void {
    this.revertComponents()
  }

  public enableLoading(): void {
    this.loading = true
  }

  public disableLoading(): void {
    this.loading = false
  }

  public revertComponents(): void {
    this.items.forEach((item) => item.revertComponentValue())
  }

  public async patchComponents(): Promise<void> {
    try {
      this.enableLoading()
      await Promise.all(this.items.map((item) => item.patchItem()))
      this.disableEdit()
    } catch (err) {
      throw err
    } finally {
      this.disableLoading()
    }
  }

  public async postSuggestion(): Promise<void> {
    const documentId: any = this.getDocumentId()
    
    if (!flexibleDocumentModule.flexibleDocumentService) return
    try {
      this.enableLoading()

      const FORM = new SuggestionRequest(this)
      FORM.suggestible_type = 'document_widget'
      FORM.suggestible_id = this.uuid
      FORM.project_id = this.project_id
      FORM.status = 'unresolved'
      FORM.template_id = documentId

      await this.suggestions.post(FORM)

      this.disableSuggest()
    } catch (err) {
      throw err
    } finally {
      this.disableLoading()
    }
  }

  private getDocumentId() {
    const path = window.location.pathname
    const elems = path.split('/')
    const documentId = sessionStorage.getItem('currentDocumentId')

    if (elems.length >= 4 && elems[1] === 'projects' && elems[3] === 'flexible-document') {
      return elems[4]
    }
    return documentId
  }

  private async refresh() {
    const data = await this.service.refresh()
    if (isIFlexibleDocumentWidgetResource(data)) {
      this.order = data.order
      this.anchor_uuid = data.anchor_uuid
      this.comment_count = data.comment_count
      this.title = data.title
      // this.blacklisted = data.blacklisted
      this.is_locked = data.is_locked ?? false
      this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
      this.permissions = data.permissions
      this.items = data.items?.map((item) => new FlexibleDocumentComponentCollectionResource(item, this.project_id))
    }
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case ELEMENT_EVENTS.SUGGESTION_UPDATED:
      case ELEMENT_EVENTS.UPDATED:
        this.refresh().then()
        break
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
