import Vue                             from 'vue'
import { createSocket } from '@/api'
import { canPerformAction }            from '@/helpers/canPerformAction'

import {
  convertToFlexibleDocumentCollectionItem,
  createOrderObject,
  isIFlexibleDocumentChapterResource
} from '@/helpers/FlexibleDocument'

import { ProjectUserResource }                    from '@/models/projects/ProjectUserResource'
import { ELEMENT_EVENTS }                         from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollection }         from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import {
  FlexibleDocumentItemCollectionResource,
  IFlexibleDocumentItemCollectionResource
} from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'

import { FlexibleDocumentCreateItemRequest }      from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentUpdateChapterRequest }   from '@/requests/flexibleDocument/FlexibleDocumentUpdateChapterRequest'
import { FlexibleDocumentUpdateItemOrderRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { Socket }                                 from 'socket.io-client'

type FlexibleDocumentChapterCollectionPermissions = 'can_release' | 'can_redefine' | 'can_comment' | 'can_edit' | 'can_delete' | string

export interface IFlexibleDocumentChapterResource extends IFlexibleDocumentItemCollectionResource {
  include_in_toc: boolean
  permissions: FlexibleDocumentChapterCollectionPermissions[]

  items: FlexibleDocumentItemCollection[]
  channel: string
}

export class FlexibleDocumentChapterCollectionResource extends FlexibleDocumentItemCollectionResource {

  public include_in_toc: boolean
  public items: FlexibleDocumentItemCollection[] = []

  public permissions: FlexibleDocumentChapterCollectionPermissions[]
  public channel: string

  public readonly anchor_uuid: string

  public socket?: Socket

  constructor({ items, include_in_toc, permissions, channel, ...chapter }: IFlexibleDocumentChapterResource, project_id: number) {
    super(chapter, project_id)

    this.include_in_toc = include_in_toc

    this.permissions = permissions

    this.anchor_uuid = chapter.anchor_uuid
    this.channel = channel
    this.setItems(items)

    this.openSocket()
  }

  public destroy() {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public get heading(): string {
    return this.title || 'Chapter'
  }

  public canPerformAction(key: FlexibleDocumentChapterCollectionPermissions): boolean {
    return canPerformAction<FlexibleDocumentChapterCollectionPermissions>(this.permissions, key)
  }

  public async patch(body: FlexibleDocumentUpdateChapterRequest): Promise<FlexibleDocumentChapterCollectionResource> {
    const { data } = await this.service.patch({ body }) as DetailResponse<IFlexibleDocumentChapterResource>
    this.title = data.title
    this.include_in_toc = data.include_in_toc
    return this
  }

  public async patchOrder(body: Pick<FlexibleDocumentUpdateItemOrderRequest, 'items'>): Promise<FlexibleDocumentChapterCollectionResource> {
    await this.service.patchOrder({ body: { anchor_uuid: this.uuid, ...body } })
    return this
  }

  public getOrderedObject(): Array<{ uuid: string, order: number }> {
    return this.items.map(createOrderObject)
  }

  public reorderItems(): FlexibleDocumentChapterCollectionResource {
    let index = 0
    for (const item of this.items) {
      item.order = index
      index++
    }
    return this
  }

  public async addItem(body: FlexibleDocumentCreateItemRequest, index: number, templateId: any): Promise<DetailResponse<FlexibleDocumentItemCollectionResource>> {
    try {
      const data = await this.service.addItem({ body }, templateId)
      this.items.splice(index, 0, data.data as FlexibleDocumentItemCollection)
      this.reorderItems()
      await this.patchOrder({ items: this.getOrderedObject() })
      return data
    } catch (err) {
      throw err
    }
  }

  public async refresh() {
    const data  = await this.service.refresh()
    if (isIFlexibleDocumentChapterResource(data)) {
      this.order = data.order
      this.comment_count = data.comment_count
      this.title = data.title
      // this.blacklisted = data.blacklisted
      this.is_locked = data.is_locked ?? false
      this.allocated_users = data.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
      this.permissions = data.permissions
    }
  }

  private setItems(items: FlexibleDocumentItemCollection[]): void {
    Vue.set(this, 'items', items?.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
        .sort((a, b) => (a.order > b.order) ? 1 : -1) ?? [])
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case ELEMENT_EVENTS.UPDATED:
        this.refresh()
        break
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
