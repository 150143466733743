


























































































































































import { projectModule } from '@/store'
import { WidgetMixin } from '@/plugins/widget-mixin'
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'
import { getProposalOrder, orderProposals, ProposalOrder } from '@/helpers/proposals'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'
import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'

import draggable from 'vuedraggable'
import Checkbox from '@/components/inputs/Checkbox.vue'
import DefaultModal from '@/components/modals/Default.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalPreview from '@/components/tiles/ProposalPreview.vue'
import DataObjectCreateProposal from './DataObjectComponents/DataObjectCreateProposal.vue'
import DataObjectModalSelectProcess from './DataObjectComponents/DataObjectModalSelectProcess.vue'
import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'

@Component({
  name: 'TableListWidget', components: {
    Checkbox, draggable, SmallLoader, DefaultModal, ProposalPreview, DataObjectModalSelectProcess, DataObjectCreateProposal
  }
})
export default class TableListWidget extends Mixins(WidgetMixin) {
  @Prop() public metaTags!: any

  // data
  private selectProcessModalOpen: boolean = false
  private savingProcessModal: boolean = false
  private settingsModalOpen: boolean = false
  private savingSettings: boolean = false
  public proposalsLoading: boolean = false


  private reorderModal: { open: boolean, moved: boolean, loading: boolean, proposals: ProposalCollectionResource[] } = {
    open: false, moved: false, loading: false, proposals: []
  }

  @Watch('reorderModal.open')
  private onReorderModalChange(): void {
    this.reorderModal.proposals = [...this.item.proposals]
  }

  // handlers
  private onInput(item: FlexibleDocumentComponentCollectionResource): void {
    this.getProposals()
  }

  // getters
  private get selectedProcess(): ProcessResource | null {
    return this.process_id ? projectModule.getProcessById(+this.process_id.component_value) : null
  }

  private get process_id(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'process_id') ?? null
  }

  private get process_filters(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'process_filters') ?? null
  }

  private get status(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'status') ?? null
  }

  private get proposal_ids(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'proposal_ids') ?? null
  }

  private get proposal_order_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'proposal_order') ?? null
  }

  private get proposal_show_title_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'show_title') ?? null
  }

  private get proposal_show_numbering_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'show_numbering') ?? null
  }

  private get proposal_include_toc_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'include_widget_in_toc') ?? null
  }

  private get process_component_selection_component(): FlexibleDocumentComponentCollectionResource | null {
    return this.items.find((item) => item.component_key === 'process_component_selection') ?? null
  }

  private get proposal_order(): ProposalOrder[] {
    return this.proposal_order_component?.component_value ? this.proposal_order_component.component_value.map((order: ProposalOrder) => new ProposalOrder(order)) : []
  }

  private get componentValues(): Map<string, any> {
    return new Map(this.items.map((item) => [item.component_key, item.component_value]))
  }

  private get proposals(): ProposalCollectionResource[] {
    return this.item.proposals
  }

  private get blacklistedProcessComponentIds(): number[] {
    if (this.process_component_selection_component) {
      return this.process_component_selection_component.component_value
    }

    return []
  }

  private goToProposal(proposal: ProposalCollectionResource, event: MouseEvent): void {
    event.stopPropagation()
    if (this.item && +this.$route.params.proposal_id !== proposal.id) {
      this.$router.push({ name: 'projects-flexible-document-proposal', params: { process_id: `${proposal.process_id}`, element_id: `${this.item.uuid}`, proposal_id: `${proposal.id}` } })
    }
  }

  private openSelectionModal(): void {
    if (this.canOpenEdit) this.selectProcessModalOpen = true
  }

  private async updateSwitch(value: boolean, element: FlexibleDocumentComponentCollectionResource): Promise<void> {
    element.updateComponentValue(value)
  }

  private async getProposals(): Promise<void> {
    if (!this.selectedProcess) {
      return
    }
    this.proposalsLoading = true
    this.item.loading = true

    try {
      let body: ProposalIndexRequest = {}
      // If the process filters is empty the proposals are not autofilled based on filter criteria
      // So we fetch proposals based on the selected proposal ids
      if (this.process_filters?.component_value === null || this.process_filters?.component_value === '') {
        body.proposal_ids = this.componentValues.get('proposal_ids')
        // If the process filters is filled we use that data to filter the proposals
      } else {
        body = {...body, ...this.process_filters?.component_value}
      }

      const { data: proposals } = await this.selectedProcess.getProposals(body)

      if (proposals && this.proposal_order_component) {
        this.$set(this.item, 'proposals', orderProposals({ proposals, order: this.proposal_order }))
      } else {
        this.$set(this.item, 'proposals', proposals)
      }
      this.proposalsLoading = false
      
    } catch (e) {
      this.proposalsLoading = false
      console.error(e)
    }

    this.item.loading = false
  }

  private sortProposals(): void {
    this.reorderModal.moved = true
  }

  private async saveReorder(): Promise<void> {
    if (this.proposal_order_component) {
      this.reorderModal.loading = true
      this.proposal_order_component.updateComponentValue(getProposalOrder({ proposals: this.reorderModal.proposals }))
      await this.proposal_order_component?.patchItem()
      this.reorderModal.loading = false
      this.reorderModal.open = false
      this.getProposals()
    }
  }

  private cancelReorder(): void {
    this.reorderModal.open = false
  }

  private async handleCancel(): Promise<void> {
    await this.cancel()
    await this.getProposals()
  }

  // hooks
  private async mounted(): Promise<void> {
    this.getProposals()
  }

  private created() {
    this.$root.$on('deleteWidgetEvent', () => {
      this.getProposals()
    })
  }

  private beforeDestroy() {
    this.$root.$off('deleteWidgetEvent', () => this.getProposals())
  }

  private cancelSettingsModal(): void {
    this.settingsModalOpen = false
    this.proposal_show_title_component?.revertComponentValue()
    this.proposal_show_numbering_component?.revertComponentValue()
    this.proposal_include_toc_component?.revertComponentValue()
  }

  private async saveSettings(): Promise<void> {
    this.savingSettings = true
    try {
      await Promise.all([
        this.proposal_show_title_component?.patchItem(),
        this.proposal_include_toc_component?.patchItem(),
        this.proposal_show_numbering_component?.patchItem(),
      ])
      this.settingsModalOpen = false
    } catch (e) {
      console.error(e)
    } finally {
      this.savingSettings = false
    }
  }

  private closeSelectProcessModal(): void {
    this.process_id?.revertComponentValue()
    this.status?.revertComponentValue()
    this.proposal_ids?.revertComponentValue()
    this.process_component_selection_component?.revertComponentValue()
    this.selectProcessModalOpen = false
  }

  private async saveSelectProcessModal(): Promise<void> {
    this.savingProcessModal = true
    try {
      await Promise.all([
        this.process_id?.patchItem(),
        this.status?.patchItem(),
        this.proposal_ids?.patchItem(),
        this.process_filters?.patchItem(),
        this.process_component_selection_component?.patchItem()
      ])
      this.selectProcessModalOpen = false
      this.getProposals()
    } catch (e) {
      console.error(e)
    } finally {
      this.savingProcessModal = false
    }
  }
}
