import { API } from '@/api'
import { DocumentPresetPatchRequest } from '@/requests/documents/DocumentPresetPatchRequest'
import { DocumentPresetRestoreRequest } from '@/requests/documents/DocumentPresetRestoreRequest'
import { DocumentPresetResource, IDocumentPresetResource } from '@/models/documents/DocumentPresetResource'
import { DocumentPresetVersionResource, IDocumentPresetVersionResource } from '@/models/documents/DocumentPresetVersionResource'

export class OrganisationDocumentPresetResource extends DocumentPresetResource {

  constructor(preset: IDocumentPresetResource) {
    super(preset)
  }

  public async patch(body: DocumentPresetPatchRequest): Promise<OrganisationDocumentPresetResource> {
    const { data } = await API.patch(`organisation/documents/presets/${this.id}`, body)
    this.setData(data.data)
    return this
  }

  public async markAllAsRead(): Promise<OrganisationDocumentPresetResource> {
    const { data } = await API.patch(`organisation/documents/presets/${this.id}/markAsRead`)
    this.setData(data)
    return this
  }

  public async delete(): Promise<OrganisationDocumentPresetResource> {
    await API.delete(`organisation/documents/presets/${this.id}`)
    return this
  }

  public async getVersions(): Promise<any> {
    const { data } = await API.get(`organisation/documents/presets/${this.id}/versions`)
    return data.data.map((version: IDocumentPresetVersionResource) => new DocumentPresetVersionResource(version))
  }

  public async restoreVersion({
                                versionId,
                                body
                              }: { versionId: number, body: DocumentPresetRestoreRequest }): Promise<OrganisationDocumentPresetResource> {
    const { data } = await API.post(`organisation/documents/presets/${this.id}/versions/${versionId}`, body)
    this.setData(data)
    return this
  }


}
