













import { Component, Vue, Watch } from 'vue-property-decorator'

import Notifications               from '@/components/messages/Notifications.vue'
import { AuthUserResource }        from '@/models/users/AuthUserResource'
import { userModule } from '@/store'
import { AuthService } from '@/services/auth'
import { OrganisationService } from '@/services/organisation'

const authService: AuthService = new AuthService()
const organisationService: OrganisationService = new OrganisationService()

@Component({
  components: {
    Notifications
  }
})
export default class App extends Vue {
  private authRequest: any = null
  public loading: boolean = true

  private mounted() {
    let isCallback: boolean = false

    this.$msal.handleCallback((error: any, response: any) => {
      isCallback = true

      if (this.authRequest) clearTimeout(this.authRequest)
      
      this.$msal.setIsAuthenticated(!!response)
      this.submitToken(response.idToken.rawIdToken)
    })

    if (this.authRequest) clearTimeout(this.authRequest)

    this.authRequest = setTimeout(() => {
      if (!this.$msal.isAuthenticated) {
        this.singInMsal()
      }
      else {
        if (!isCallback) this.redirectUser()
      }
    }, 1000)

    const regex = /^\S+(projects)(\/)\d\S+/;
    const matches = regex.test(window.location.pathname);

    if (matches) localStorage.setItem('urlToRedirect', window.location.pathname)
  }

  private async submitToken(token: string) {
    await authService.loginByToken(token)
    await organisationService.getTheme()

    this.redirectUser()
  }

  private async redirectUser() {
    this.loading = false
    await userModule.getMe()

    const redirectionUrl = localStorage.getItem('urlToRedirect')

      if (redirectionUrl) {
        await this.$router.push(redirectionUrl)
      }
      // else if (this.$route.query.next) {        
      //   await this.$router.push({ path: this.$route.query.next.toString() })
      // } 
      // else {
      //   await this.$router.push({ name: 'dashboard' })
      // }
  }

  private async signOutMsal() {
    await this.$msal.signOut()
  }

  private async singInMsal() {
    await this.$msal.signIn();
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  @Watch('user.id')
  private onUserAvailable(val: number | undefined, oldVal: number | undefined): void {
    // @ts-ignore
    const userLane = window.Userlane || null

    if (oldVal !== val && userLane && this.user) {
      userLane('identify', this.user.id, {
        can_create_project: this.user.canPerformAction('can_start_project'),
        can_edit_team: this.user.canPerformAction('can_start_project'),
        is_library_owner: this.user.canPerformAction('can_upload_document'),
        roles: this.user.organisation_roles,
        environment: this.user.environment
      })
    }
  }
}
