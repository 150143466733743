
import {Component, Prop, Vue} from 'vue-property-decorator'
import {ProcessResource} from '@/models/process/ProcessResource'
import {DocumentResource} from '@/models/documents/DocumentResource'

import Viewer from '@/components/document/Viewer.vue'
import NoItems from '@/components/partials/NoItems.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import CreateProposalFullScreenModal from '@/components/proposal/modals/CreateProposalFullScreenModal.vue'

@Component({
  components: {
    Viewer,
    NoItems,
    SmallLoader,
    CreateProposalFullScreenModal
  }
})
export default class CreateProposalDocumentViewer extends Vue {

  @Prop()
  private readonly open!: boolean

  @Prop()
  private readonly process?: ProcessResource

  @Prop({default: true})
  private readonly isFetchingProcess!: boolean

  @Prop()
  private readonly document?: DetailResponse<DocumentResource>

  @Prop({default: true})
  private readonly isFetchingDocument!: boolean

  @Prop({default: null})
  private readonly id!: any

  @Prop()
  private project: any

  private fontBigger: boolean = false

  private get proposalId() {
    return this.document?.data?.proposal_id || this.id
  }

  private get documentTitle() {
    return this.document?.data.name
  }

  private close() {
    this.$emit('close')
  }

  private downloadFile(): void {
    if (this.document) {
      window.open(this.document.data.link, '_blank')
    }
  }

}
