import { render, staticRenderFns } from "./SmallLoader.vue?vue&type=template&id=03892f15&scoped=true&"
import script from "./SmallLoader.vue?vue&type=script&lang=ts&"
export * from "./SmallLoader.vue?vue&type=script&lang=ts&"
import style0 from "./SmallLoader.vue?vue&type=style&index=0&id=03892f15&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03892f15",
  null
  
)

export default component.exports