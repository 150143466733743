















































































import { userModule, stateModule } from '@/store'
import { Component, Vue, Prop, Watch }    from 'vue-property-decorator'

import Dots        from '@/components/widgets/DotDotDot.vue'
import UserImage   from '@/components/user/UserImage.vue'
import AddComment  from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import SwitchInput from '@/components/inputs/Switch.vue'

import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

import { SuggestionResource } from '@/models/suggestions/SuggestionResource'

@Component({
  name: 'Suggestion',
  components: {
    Dots,
    UserImage,
    AddComment,
    SmallLoader,
    SwitchInput
  }
})
export default class Suggestion extends Vue {

  // Props
  @Prop()
  private suggestion!: SuggestionResource

  @Prop()
  private users!: ProjectUserResource[] | null

  @Prop()
  private parent_id!: number

  @Prop()
  private readonly!: boolean

  @Prop({ default: true })
  private canReply!: boolean

  @Prop({ default: true })
  private canChangeStatus!: boolean

  private loading: boolean = false
  private isReplying: boolean = false

  private viewChanges: boolean = false

  private foldedOut: boolean = false
  private needsFold: boolean = false

  @Watch('viewChanges')
  private onViewChangesChange(val: boolean): void {
    if (val) {
      this.foldedOut = true
    }
  }

  @Watch('suggestion.id')
  private onSuggestionChange(): void {
    const CONTENT_WRAPPER = this.$refs.wrapper as HTMLElement

    if (CONTENT_WRAPPER.scrollHeight > 54) {
      this.$nextTick(() => {
        this.needsFold = true
      })
    }
  }

  // Computed
  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get statusClass(): string {
    return !this.suggestion.status ? '' : this.suggestion.status.toLowerCase()
  }

  private get isOwnSuggestion(): boolean {
    return this.suggestion.user ? (this.suggestion.user.id === (this.user ? this.user.id : 0)) : false
  }

  private get canDeleteSuggestion(): boolean {
    return this.suggestion.canPerformAction('can_delete_suggestion')
  }

  private get canResolveSuggestion(): boolean {
    return this.suggestion.canPerformAction('can_resolve_suggestion')
  }

  private created(): void {
    this.suggestion.socket?.on('event', this.refreshData)
  }

  private mounted(): void {
    const CONTENT_WRAPPER = this.$refs.wrapper as HTMLElement

    if (CONTENT_WRAPPER.scrollHeight > 54) {
      this.$nextTick(() => {
        this.needsFold = true
      })
    }
  }

  private beforeDestroy(): void {
    this.loading = false
    this.suggestion.socket?.off('event', this.refreshData)
  }

  private refreshData(event: string): void {
    switch (event) {
      case 'suggestion updated':
      case 'suggestion created':
        this.refreshSuggestion()
        break
    }
  }

  private refreshSuggestion(): void {
    this.suggestion.refresh()
  }

  // Methods
  private deleteReply(): void {
    this.suggestion.refresh()
  }

  private toggleReply(): void {
    this.isReplying = !this.isReplying
  }

  private closeReply(): void {
    this.isReplying = false
  }

  private toggleFoldedOut(): void {
    this.foldedOut = !this.foldedOut
  }

  private async approve(): Promise<void> {
    this.loading = true
    try {
      await this.suggestion.approve()
      this.$emit('refresh')
      this.$root.$emit('updateProposals')
    } finally {
      this.loading = false
      this.closeReply()
    }
  }

  private async decline(): Promise<void> {
    this.loading = true
    try {
      await this.suggestion.decline()
      this.$emit('refresh')
      this.$root.$emit('updateProposals')
    } finally {
      this.loading = false
      this.closeReply()
    }
  }

  private async postReply(message: string): Promise<void> {
    this.loading = true
    try {
      await this.suggestion.comments.post(message)
      this.suggestion.refresh()
      this.closeReply()
    } finally {
      this.loading = false
    }

  }

  // Event handlers
  private async deleteSuggestion(): Promise<void> {
    if (confirm('Are you sure you want to delete this suggestion?')) {
      this.loading = true
      try {
        await this.suggestion.delete()
        this.$emit('deleteSuggestion', this.suggestion.id)
      } finally {
        this.loading = false
      }
    }
  }
}
