
























import cloneDeep                from 'lodash.clonedeep'
import { Vue, Component, Prop } from 'vue-property-decorator'

const minColumnCount: number = 2
const maxColumnCount: number = 5
const minRowCount: number = 1
const maxRowCount: number = Infinity

const createEmptyArray = (count: number): string[] => {
  const array: string[] = []
  for (let i = 0; i < count; i++) {
    array.push('')
  }
  return array
}

import CommentBox from '@/components/inputs/CommentBox.vue'

@Component({
  components: {
    CommentBox
  }
})
export default class TableInput extends Vue {

  @Prop()
  private value!: [string[]]

  private hoverIndex: { row: number | undefined, column: number | undefined } = {
    row: undefined,
    column: undefined
  }

  private get rowCount(): number {
    return this.value.length
  }

  private get columnCount(): number {
    return this.value[0]?.length || 0
  }

  private get canDeleteRow(): boolean {
    return this.rowCount > minRowCount
  }

  private get canDeleteColumn(): boolean {
    return this.columnCount > minColumnCount
  }

  private get canAddColumn(): boolean {
    return this.columnCount < maxColumnCount
  }

  private get canAddRow(): boolean {
    return this.rowCount < maxRowCount
  }

  private get rowStyle(): Dictionary<string> {
    return { gridTemplateColumns: `repeat(${this.columnCount}, 1fr)` }
  }

  private hoverCell(rowIndex?: number, columnIndex?: number): void {
    this.hoverIndex.row = rowIndex
    this.hoverIndex.column = columnIndex
  }

  private addRow(): void {
    this.value.push(createEmptyArray(this.columnCount))
    this.updateInput()
  }

  private addColumn(): void {
    for (const row of this.value) {
      row.push('')
    }
    this.updateInput()
  }

  private deleteRow(index: number): void {
    this.value.splice(index, 1)
    this.updateInput()
  }

  private deleteColumn(index: number): void {
    for (const row of this.value) {
      row.splice(index, 1)
    }
    this.updateInput()
  }

  private updateValue(rowIndex: number, columnIndex: number, value: string): void {
    if (this.value[rowIndex] !== undefined && this.value[rowIndex][columnIndex] !== undefined) {
      const clone = cloneDeep(this.value)
      clone[rowIndex][columnIndex] = value
      this.$emit('input', clone)
    }
  }

  private updateInput(): void {
    const clone = cloneDeep(this.value)
    this.$emit('input', clone)
  }
}
