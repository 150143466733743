import { ProposalCollectionResource }                  from '@/models/proposals/ProposalCollectionResource'
import { FlexibleDocumentWidgetCollectionResource }    from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'

export class SuggestionRequest {
  public values: Array<{ component_id: number | string, data: string }>
  public suggestible_type?: string = ''
  public suggestible_id?: number | string = 0
  public status?: string = 'unresolved'
  public project_id?: number = 0
  public template_id?: any = ''

  constructor(resource: ProposalCollectionResource | FlexibleDocumentWidgetCollectionResource) {
    if (resource instanceof ProposalCollectionResource) {
      this.values = resource.components.map((c) => ({ component_id: c.component_id, data: c.value }))
    } else {
      this.values = resource.items.map((item) => ({ component_id: item.uuid, data: item.component_value }))
    }
  }

}
