
























































































import { userModule } from '@/store'
import { Component, Vue, Prop }    from 'vue-property-decorator'

import Likes       from '@/components/comments/Likes.vue'
import Dots        from '@/components/widgets/DotDotDot.vue'
import UserImage   from '@/components/user/UserImage.vue'
import CommentBox  from '@/components/inputs/CommentBox.vue'
import AddComment  from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { CommentResource }     from '@/models/comments/CommentResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

@Component({
  name: 'Comment',
  components: {
    Dots,
    Likes,
    UserImage,
    CommentBox,
    AddComment,
    SmallLoader
  }
})
export default class Comment extends Vue {

  // Props
  @Prop()
  private comment!: CommentResource

  @Prop()
  private users!: ProjectUserResource[] | null

  @Prop()
  private parent_id!: number

  @Prop()
  private readonly!: boolean

  @Prop({ default: true })
  private canReply!: boolean

  @Prop({ default: true })
  private canChangeStatus!: boolean

  @Prop({ default: true })
  private canComment!: boolean

  private loading: boolean = false
  private isReplying: boolean = false
  private isEditing: boolean = false

  // Computed
  private get isTopLevel(): boolean {
    return !this.parent_id
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get statusClass(): string {
    return !this.comment.status || !this.isTopLevel ? '' : this.comment.status.toLowerCase()
  }

  private get isOwnComment(): boolean {
    return this.comment.user_info.id === (this.user ? this.user.id : 0)
  }

  private get canDeleteComment(): boolean {
    return this.comment.canPerformAction('can_delete_comment')
  }

  private get canEditComment(): boolean {
    return this.comment.canPerformAction('can_edit_comment')
  }

  private get canLikeComment(): boolean {
    return this.comment.canPerformAction('can_like_comment')
  }

  private get canResolveComment(): boolean {
    return this.comment.canPerformAction('can_resolve_comment')
  }

  private beforeDestroy(): void {
    this.loading = false
    this.comment.destroy()
  }

  private getProjectId() {
    const path = window.location.pathname
    const elems = path.split('/')

    if (elems.length >= 4 && elems[1] === 'projects' && elems[3] === 'flexible-document') {
      return elems[2]
    }
    return undefined    
  }

  // Methods
  private deleteReply(): void {
    this.comment.refresh()
  }

  private toggleReply(): void {
    this.isReplying = !this.isReplying
  }

  private closeReply(): void {
    this.isReplying = false
  }

  private enableEdit(): void {
    this.isEditing = true
  }

  private disableEdit(): void {
    this.isEditing = false
  }

  private async patch(message: string): Promise<void> {
    this.loading = true
    
    const body: any = { message, status: this.comment.status }
    const projectId = this.getProjectId()
    
    if (projectId) body.project_id = projectId

    try {
      await this.comment.patch(body)
    } finally {
      this.loading = false
      this.closeReply()
      this.disableEdit()
      // this.$emit('updateComment')
    }
  }

  private async accept(): Promise<void> {
    this.loading = true
    try {
      await this.comment.accept()
    } finally {
      this.loading = false
      this.closeReply()
      this.disableEdit()
      // this.$emit('updateComment')
    }
  }

  private async decline(): Promise<void> {
    this.loading = true
    try {
      await this.comment.decline()
    } finally {
      this.loading = false
      this.closeReply()
      this.disableEdit()
      // this.$emit('updateComment')
    }
  }

  private async postReply(message: string): Promise<void> {
    this.loading = true
    try {
      await this.comment.addReply(message)
      this.closeReply()
    } finally {
      this.loading = false
    }

  }

  // Event handlers
  private async deleteComment(): Promise<void> {
    if (confirm('Are you sure you want to delete this comment?')) {
      this.loading = true
      try {
        await this.comment.delete()
        this.$emit('deleteComment', this.comment.id)
      } finally {
        this.loading = false
      }
    }
  }

  private async likeComment(): Promise<void> {
    await this.comment.like()
  }
}
