import * as msal from "msal";
import Vue, { PluginObject, VueConstructor } from "vue";

declare module "vue/types/vue" {
    interface Vue {
        $msal: MsalPlugin;
    }
}

export interface MsalPluginOptions {
    clientId: string;
    loginAuthority: string;
    passwordAuthority: string;
    knownAuthority: string;
    redirectUri: string;
}

let msalInstance: msal.UserAgentApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

    private pluginOptions: MsalPluginOptions = {
        clientId: "",
        loginAuthority: "",
        passwordAuthority: "",
        knownAuthority: "",
        redirectUri: ""
    };

    public isAuthenticated = false;


    public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.pluginOptions = options;
        this.initialize(options);
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
    }

    private initialize(options: MsalPluginOptions) {
        const msalConfig: msal.Configuration = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority],
                redirectUri: options.redirectUri
            },
            system: {
            }
        };
        
        msalInstance = new msal.UserAgentApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();

        
    }

    public handleCallback(callback: any) {
        return msalInstance.handleRedirectCallback(callback)
    }

    public isInProgress() {
        return msalInstance.getLoginInProgress()
    }

    public setIsAuthenticated(flag: boolean) {
        this.isAuthenticated = flag;
    }


    public async signIn() {
        try {
            const loginRequest: any = {
                scopes: ["openid"],
            };
            await msalInstance.loginRedirect(loginRequest);
            
        } catch (err) {
            console.log(err);
            
            // handle error
            if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                    const passwordResetResponse: any = await msalInstance.loginRedirect({
                        scopes: ["openid"],
                        authority: this.pluginOptions.passwordAuthority
                    });
                     this.isAuthenticated = !!passwordResetResponse.account;
                } catch (passwordResetError) {
                    console.error(passwordResetError);
                }
            } else {
                this.isAuthenticated = false;
            }

        }
    }

    public async signOut() {
        // await msalInstance.handleRedirectPromise()
        if (this.isAuthenticated) await msalInstance.logout();
        const redirectUrl = localStorage.getItem('urlToRedirect')
        sessionStorage.clear();
        localStorage.clear();

        if (redirectUrl) localStorage.setItem('urlToRedirect', redirectUrl)
        this.isAuthenticated = false;
    }

    public async acquireToken() {
        const request = {
            account: msalInstance.getAccount(),
            scopes: ["openid"]
        };
        
        try {
            const response = await msalInstance.acquireTokenSilent(request);
            return response;            
        } catch (error) {
            // console.log(error);
            
            if (error instanceof msal.InteractionRequiredAuthError) {
                return msalInstance.acquireTokenPopup(request).catch((popupError) => {
                    console.error(popupError);
                });
            }
            return false;
        }
    }

    private getIsAuthenticated(): boolean {        
        const account: any = !!msalInstance.getAccount();
        return account;
    }
}