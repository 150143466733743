
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DocumentSummary extends Vue {
    @Prop() private doc!: any
    @Prop() private service!: any

    public summary: string | null = null
    public show: boolean = false
    public loading: boolean = false

    private async getSummary() {
        this.loading = true
        if (!this.doc || !this.doc.corlytics_id) return

        try {
            const summary = await this.service.getSummary(this.doc.corlytics_id)
            this.summary = summary.summary
            this.loading = false
        }
        catch(e) {
            this.summary = 'Error occurred while loading summary...'
            this.loading = false
        }
    }

    public switchShow(type: boolean) {
        if (type && !this.summary) this.getSummary()
        this.show = type
    }
}

