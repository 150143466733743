

















































import { Location } from 'vue-router'
import { Component, Vue, Prop } from 'vue-property-decorator'

import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'
import StatusBadge from '@/components/statuses/StatusBadge.vue'
import Metatags from '@/components/metatags/Metatags.vue'
@Component({
  components: {StatusBadge, Metatags}
})
export default class ProposalSimple extends Vue {
  @Prop()
  private proposal!: ProposalSummaryCollectionResource

  @Prop({ default: false })
  private isSelected!: boolean

  @Prop({ default: false })
  private canSelect!: boolean

  @Prop({default: true})
  private canSelectMultiple!: boolean

  @Prop({ default: false })
  private isDisabled!: boolean

  @Prop() private data!: any

  @Prop({default: () => []})
  private readonly blacklistedComponentIds!: number[]

  @Prop() public metaTags!: any

  private select(): void {
    this.$emit('select')
  }

  private get selectComponent(): string {
    if (this.canSelectMultiple) {
      return this.isSelected ? 'selectedBoxIcon' : 'selectBoxIcon'
    }
    return this.isSelected ? 'radiobuttonActiveIcon' : 'radiobuttonIcon'
  }

  public displayMetaTags(type: string) {
    if (!this.data?.length) return true

    const proposal = this.data.find((item: any) => item.id === this.proposal.id)
    if (!proposal) return false
  
    const component = proposal?.components.find((item: any) => item.label === type)
    if (!component) return false

    return !this.blacklistedComponentIds.includes(component.component_id)
  }

  public hideOryginalMetaTags(type: string) {
    const toHide = ['Service line', 'theme', 'Reg Categories', 'Client Taxonomy']

    return toHide.includes(type)
  }
}
