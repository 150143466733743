
































import {capitalize} from '@/helpers'
import {Component, Prop, Vue} from 'vue-property-decorator'

import DropdownInput from '@/components/inputs/Dropdown.vue'

import { ProcessResource } from '@/models/process/ProcessResource'
import { FlexibleDocumentComponentCollectionResource } from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import { ComponentResource } from '@/models/widgets/ComponentResource'

@Component({
  components: {
    DropdownInput,
  }
})
export default class SelectProcessContextMenu extends Vue {

  @Prop()
  private selectedableProcesses!: ProcessResource[]

  @Prop({required: true})
  private process_id!: FlexibleDocumentComponentCollectionResource

  @Prop()
  private process_component_selection!: FlexibleDocumentComponentCollectionResource

  private form: { process_id: string, components: number[] } = {
    process_id: '',
    components: []
  }

  private initialForm: { process_id: string, components: number[] } = {
    process_id: '',
    components: []
  }

  private get mappedSelectableProcesses(): SelectItem[] {
    return this.selectedableProcesses.map(({name, id}) => ({ label: capitalize(name), value: `${id}` }))
  }

  private get selectedProcess(): ProcessResource | undefined {
    return this.selectedableProcesses.find(({ id }) => id === +this.form.process_id)
  }

  private created() {
    this.setInitialState()
  }

  private async resetComponents(value: string | number | undefined | null | Array<string | number | undefined | null>) {
    await this.$nextTick()
    if (this.initialForm.process_id === value) {
      // When value is set back to initial value, shall we also set the initial selected components back?
      this.form.components = this.initialForm.components
    } else {
      this.form.components = []
    }
  }

  private setInitialState() {
    const process_id = this.initialForm.process_id = typeof this.process_id.component_value === 'string' ? this.process_id.component_value : ''

    this.form.process_id = process_id
    this.initialForm.process_id = process_id
    const process = this.selectedableProcesses.find((p) => p.id === +process_id)
    if (process) {
      const blacklistedComponents = Array.isArray(this.process_component_selection?.component_value) ? this.process_component_selection?.component_value as number[] : []
      // the selected components are the opposite from what we store
      // Since we blacklist the components but we want to show the user which one will show as selected
      const components = this.getSelectedableComponents(process).map(({ id }) => id).filter((c) => !blacklistedComponents.includes(c))
      
      this.form.components = components
      this.initialForm.components = components
    }
  }
  
  private get selectableComponents(): SelectItem[] {
    if (!this.selectedProcess) return []
    
    return this.getSelectedableComponents(this.selectedProcess).map(({label, id}) => ({ label: label ?? '', value: id  }))
  }
  
  private getSelectedableComponents(process: ProcessResource): ComponentResource[] {
    const selectable = process.widgets.map((widget) => widget.components.filter(({label, visible}) => !(label === '' || label === 'document_id' || !visible))
      ).flat()
    return selectable
  }

  private close() {
    this.$emit('close')
  }

  private apply() {
    this.$emit('apply', this.form)
  }

}
