
import {projectModule} from '@/store'
import {sleep} from '@/api'
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'

import {WidgetResource} from '@/models/widgets/WidgetResource'
import {ProcessResource} from '@/models/process/ProcessResource'
import {ProjectUserResource} from '@/models/projects/ProjectUserResource'
import {ProposalCreateRequest} from '@/requests/proposals/ProposalCreateRequest'
import {ProposalSimilarityRequest} from '@/requests/proposals/ProposalSimilarityRequest'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'

import Users from '@/components/list/Users.vue'
import TextInput from '@/components/inputs/Text.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalSimple from '@/components/tiles/ProposalSimple.vue'
import WidgetList from '@/components/proposal/create/WidgetList.vue'
import FullScreenModal from '@/components/modals/FullScreenModal.vue'
import SimilarityModal from '@/components/proposal/partials/Similarity.vue'

@Component({
  components: {
    Users,
    TextInput,
    UserPicker,
    WidgetList,
    SmallLoader,
    ProposalSimple,
    FullScreenModal,
    SimilarityModal,
  }
})
export default class CreateProposalFullScreenModal extends Vue {

  @Prop()
  private readonly open!: boolean

  @Prop()
  private readonly process?: ProcessResource

  @Prop({ default: true })
  private readonly isFetchingProcess!: boolean

  @Prop({default: true})
  private readonly redirect!: boolean

  @Prop({default: null})
  private proposalId: any

  @Prop({default: () => []})
  private readonly preselectedConnectedProposals!: number[]

  @Prop()
  private readonly documentTitle!: string

  @Prop()
  private project: any

  private overrideRedirect: boolean = false
  private loading: boolean = false
  private form: ProposalCreateRequest | null = null

  private similarityModal: {
    open: boolean
    proposals: ProposalCollectionResource[]
    proposal: ProposalCollectionResource | null
    selected: number
  } = {
    open: false,
    proposals: [],
    proposal: null,
    selected: 0
  }

  private isIndexHidden: boolean = true

  private get projectUsers() {
    return projectModule.users
  }

  private get previousProcess() {
    return projectModule.detail?.getProcessByOrder((this.process?.order ?? 0) - 1)
  }

  private get needsAllocation(): boolean {
    return this.process?.statuses.find((status) => status.order === 0)?.need_allocation ?? false
  }

  private get allocatedUsers(): ProjectUserResource[] {
    return this.projectUsers.filter((user) => this.form?.notify_user.includes(user.id))
  }

  private get widgets(): WidgetResource[] | null {
    return this.process ? this.process.widgets : null
  }

  @Watch('process', { immediate: true })
  private onProcessChange(process: ProcessResource) {
    if (!process) return
    this.form = new ProposalCreateRequest({
      widgets: process.widgets,
      parent_ids: this.preselectedConnectedProposals
    })

    if (this.previousProcess) {
      this.inheritPreviousProposalName()
    }
  }

  private close() {
    this.$emit('close')
}

  private toggleIndex() {
    this.isIndexHidden = !this.isIndexHidden
  }

  private inheritPreviousProposalName(): void {
    if (!this.form || !this.process) return

    // If one proposal is selected, the proposal name is not filled yet AND the process wants to inherit proposals names from its parents we will set the proposal name with the select parent proposal
    if (this.documentTitle && !this.form.proposal_name && this.process.inherit_parent_title) {
      this.form.proposal_name = this.documentTitle
    }
  }

  private createNewProposal() {
    this.checkSimilarity()
  }

  private async checkSimilarity(): Promise<void> {
    if (!(this.process && this.form)) return

    this.loading = true
    try {
      const body = new ProposalSimilarityRequest({proposal: this.form})
      const {proposal, similarity} = await this.process.checkSimilarity(body)
      // If similarities found show similarities modal
      if (similarity && similarity.length > 0) {
        this.$set(this.similarityModal, 'proposals', similarity)
        this.$set(this.similarityModal, 'proposal', proposal)
        this.similarityModal.selected = similarity[0].id
        this.similarityModal.open = true
        this.loading = false
        // otherwise just create
      } else await this.createProposal()
    } catch (e) {
      // If api isn't working just create proposal.
      await this.createProposal()
    }
  }

  private closeSimilarityModal(): void {
    this.$set(this.similarityModal, 'proposals', [])
    this.$set(this.similarityModal, 'proposal', null)
    this.similarityModal.selected = 0
    this.similarityModal.open = false
  }

  private reuseProposal(): void {
    this.createProposal(this.similarityModal.selected)
  }

  private createNew(): void {
    this.createProposal()
  }

  private async createProposal(similarity_id?: number): Promise<void> {
    if (!(this.process && this.form)) throw Error('process not present')


    console.log('This is a proposal create method');
    
    const body = {...this.form}
    if (similarity_id) {
      body.similarity_id = similarity_id
    }

    this.loading = true
    try {
      const {data} = await this.process.createProposal(body)      
      await this.project?.refreshData()
      
      this.$emit('submit')
      this.$emit('close')


      await sleep(350)
      if (this.redirect && !this.overrideRedirect) {
        await this.$router.push({
          name: 'projects-detail-proposal-detail',
          params: {
            project_id: data.project_id.toString(),
            process_id: data.process_id.toString(),
            proposal_id: data.id.toString()
          }
        })
      }
    } finally {
      this.loading = false
    }
  }

  private overrideRedirectValue(value: boolean): void {
    this.overrideRedirect = value
  }

  private isRedirectOverrideSelected(value: boolean): boolean {
    return value === this.overrideRedirect
  }

}
