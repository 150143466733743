import { OrganisationThemeResource } from '@/models/organisation/OrganisationThemeResource'

export const setColors = (theme: OrganisationThemeResource): void => {
  if (window && window.document) {
    for (const key in theme) {
      if (theme.hasOwnProperty(key)) {
        if (key === 'primary') theme[key] = '#bd2e2a'
        if (key === 'secondary') theme[key] = '#737d84'
        if (key === 'tertiary') theme[key] = '#5f808f'
        if (key === 'quaternary') theme[key] = '#5199a9'
        window.document.documentElement.style.setProperty(`--${key}`, theme[key])
      }
    }
  }
}


