import { createSocket, } from '@/api'
import Vue                                     from 'vue'
import { stateModule, flexibleDocumentModule } from '@/store'
import { canPerformAction }                    from '@/helpers/canPerformAction'
import { FlexibleDocumentService }             from '@/services/flexibleDocument'
import { MultipleDocumentsService } from '@/services/multipleDocuments'
import { CommentsController }          from '@/controllers/comments/CommentsController'

import {
  flatten,
  createOrderObject,
  convertToFlexibleDocumentCollectionItem,
  isFlexibleDocumentChapterCollectionResource,
  isFlexibleDocumentSectionCollectionResource,
  isFlexibleDocumentWidgetCollectionResource,
  isWidgetIncludedInToc
} from '@/helpers/FlexibleDocument'

import { AuditsResource }                                                  from '@/models/audits/AuditsResource'
import { FlexibleDocumentItemResource }                                    from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollection }                                  from '@/models/flexibleDocument/FlexibleDocumentItemCollection'
import { IFlexibleDocumentStatusResource, FlexibleDocumentStatusResource } from '@/models/status/FlexibleDocumentStatusResource'

import { FlexibleDocumentUpdateRequest }               from '@/requests/flexibleDocument/FlexibleDocumentUpdateRequest'
import { IFlexibleDocumentMetaResource }               from './flexibleDocumentMetaResource'
import { FlexibleDocumentCreateItemRequest }           from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentUpdateStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentUpdateStatusRequest'
import { FlexibleDocumentUpdateItemOrderRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { FlexibleDocumentUpdateExportSettingsRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateExportSettingsRequest'
import { FlexibleDocumentReviewStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentReviewStatusRequest'
import { Socket }                                      from 'socket.io-client'

export type FlexibleDocumentPermissions =
    'can_change_status'
    | 'can_create_element'
    | 'can_edit_element'
    | 'can_delete_element'
    | 'can_approve_policy'
    | 'can_comment'

type ITocIndex = {
  label: string
  index: number | undefined
  parentIndex?: string
  // type: string
  children?: TocIndex[]
}

class TocIndex {
  public label: string
  public index: number | undefined
  public parentIndex?: string
  // public type: string
  public children?: TocIndex[]

  constructor(item: ITocIndex) {
    this.label = item.label
    this.index = item.index
    this.parentIndex = item.parentIndex
    this.children = item.children
  }

  public get indexString(): string {
    if (this.parentIndex) {
      return this.index ? `${this.parentIndex}${this.index}.` : `${this.parentIndex}`
    } else {
      return this.index ? `${this.index}.` : ''
    }
    // return this.parentIndex ? `${this.parentIndex}${this.index}.` : `${this.index}.`
  }

  public get title(): string {
    return `${this.indexString} ${this.label}`
  }
}

export interface IFlexibleDocumentResource {
  uuid: string
  title: string
  end_date: string
  template_id: number
  items: FlexibleDocumentItemCollection[]
  permissions: FlexibleDocumentPermissions[]
  status: IFlexibleDocumentStatusResource
  statuses: IFlexibleDocumentStatusResource[]
  meta?: IFlexibleDocumentMetaResource | null
  pdf_link: string
  had_sounding: boolean
  project_status: string
  sounding_end_date: string
  channel?: string
  updated_by?: string
  created_by?: string
}

export class FlexibleDocumentResource {
  public readonly uuid: string
  public title: string
  public end_date: string
  public template_id: number
  public permissions: FlexibleDocumentPermissions[]
  public status: FlexibleDocumentStatusResource
  public statuses: FlexibleDocumentStatusResource[]
  public pdf_link: string
  public had_sounding: boolean
  public sounding_end_date: string
  public project_status: string
  public meta: any

  public items: FlexibleDocumentItemCollection[] = []

  public readonly project_id: number
  public readonly flexibleDocumentService: FlexibleDocumentService
  public readonly multipleDocumentService: MultipleDocumentsService

  public comments: CommentsController
  public audits: AuditsResource

  public channel?: string
  public socket?: Socket


  constructor(document: IFlexibleDocumentResource, project_id: number) {
    this.uuid = document.uuid
    this.title = document.title
    this.end_date = document.end_date
    this.template_id = document.template_id
    this.permissions = document.permissions
    this.status = new FlexibleDocumentStatusResource(document.status)
    this.statuses = document.statuses.map((s) => new FlexibleDocumentStatusResource(s))
    this.pdf_link = document.pdf_link
    this.had_sounding = document.had_sounding
    this.sounding_end_date = document.sounding_end_date
    this.project_status = document.project_status
    this.meta = document.meta
    if (!this.meta.title.length) this.meta.title = document.title
    // this.meta = document.meta ? new FlexibleDocumentMetaResource(document.meta) : null

    this.channel = document.channel

    this.openSocket()

    this.project_id = project_id
    this.flexibleDocumentService = new FlexibleDocumentService({ project_id })
    this.multipleDocumentService = new MultipleDocumentsService({ project_id })

    this.comments = new CommentsController({
      id: document.template_id,
      project_id: this.project_id,
      service: this.flexibleDocumentService,
      commentableType: 'template'
    })
    this.audits = new AuditsResource({ service: this.flexibleDocumentService })
    this.audits.get()

    this.setItems(document.items)

    flexibleDocumentModule.setFlexibleDocument(this)
  }

  public destroy(): void {
    if (this.socket) {
      this.socket.off('event', (e: EventResponse) => this.handleEvent(e))
    }
  }

  public get heading(): string {
    return this.title || 'New document'
  }

  public get itemsFlat(): FlexibleDocumentItemCollection[] {
    return flatten(this.items)
  }

  public get tocIndex(): TocIndex[] {
    const items: TocIndex[] = []
    // Start with index 0
    let chapterIndex = 0
    // First level is always a chapter
    for (const chapter of this.items) {

      const includeInToc = isFlexibleDocumentChapterCollectionResource(chapter) || isFlexibleDocumentSectionCollectionResource(chapter) ? chapter.include_in_toc : false
      const includeInOverview = isFlexibleDocumentChapterCollectionResource(chapter) || isFlexibleDocumentSectionCollectionResource(chapter)
      const couldHaveChildren = isFlexibleDocumentChapterCollectionResource(chapter)

      // If chapter is included in toc +1 index and add to array
      if (includeInToc) chapterIndex++

      let sectionsOrWidgets: undefined | TocIndex[]

      // if chapter has children eq: sections and/or widgets
      if (couldHaveChildren && chapter.items) {

        // Make empty array so we can push new items
        sectionsOrWidgets = []

        // Also start counting again for sections
        let sectionOrWidgetIndex = 0

        // Loop over the sections/widgets
        for (const sectionOrWidget of chapter.items) {

          const isSectionAndIncludedInToc = isFlexibleDocumentSectionCollectionResource(sectionOrWidget) ? sectionOrWidget.include_in_toc : false
          const isWidgetAndIncludedInToc = isFlexibleDocumentWidgetCollectionResource(sectionOrWidget) ? isWidgetIncludedInToc(sectionOrWidget) : false

          // If widget and is include in toc (eq: should be widget 'data table' and component included in toc should be true)
          if (isWidgetAndIncludedInToc) {
            // Double check for TypeScript
            if (isFlexibleDocumentWidgetCollectionResource(sectionOrWidget) && isWidgetIncludedInToc(sectionOrWidget)) {
              // Loop over proposals since we don't want the widget in the TOC but the proposals
              for (const proposal of sectionOrWidget.proposals) {
                sectionOrWidgetIndex++
                sectionsOrWidgets.push(new TocIndex({
                  label: proposal.proposal_name,
                  // Exclude from index is parents are also excluded
                  parentIndex: includeInToc ? `${chapterIndex}.` : undefined,
                  // type: proposal.type,
                  index: includeInToc && isWidgetIncludedInToc(sectionOrWidget) ? sectionOrWidgetIndex : undefined
                }))
              }
            }
            // This should be a section
          } else {
            // Double check if section for TypeScript
            if (isFlexibleDocumentSectionCollectionResource(sectionOrWidget)) {

              // If included in index add +1 to sectionOrWidgetIndex
              if (isSectionAndIncludedInToc) sectionOrWidgetIndex++

              let proposals: undefined | TocIndex[]
              let proposalIndex = 0
              // Check if section has a widget 'data table' that we need to include inside the toc
              for (const widget of sectionOrWidget.items) {
                if (isFlexibleDocumentWidgetCollectionResource(widget) && isWidgetIncludedInToc(widget)) {
                  // Widget is 'data table' and include in toc

                  // Make empty array so we can push new items
                  proposals = []
                  for (const proposal of widget.proposals) {
                    proposalIndex++
                    proposals.push(new TocIndex({
                      label: proposal.proposal_name,
                      // Exclude from index is parents are also excluded
                      parentIndex: includeInToc && isSectionAndIncludedInToc ? `${chapterIndex}.${sectionOrWidgetIndex}.` : undefined,
                      // type: proposal.type,
                      index: includeInToc && isSectionAndIncludedInToc && isWidgetIncludedInToc(widget) ? proposalIndex : undefined
                    }))
                  }

                }
              }

              // Add section
              sectionsOrWidgets.push(new TocIndex({
                label: sectionOrWidget.title,
                // Exclude from index is parents are also excluded
                parentIndex: includeInToc ? `${chapterIndex}.` : undefined,
                index: includeInToc && isSectionAndIncludedInToc ? sectionOrWidgetIndex : undefined,
                children: proposals
              }))
            }
          }

        }
      }

      // Check if included in overview since we don't want to add Front page
      if (includeInOverview) {
        // Add to toc
        items.push(new TocIndex({
          label: chapter.title,
          parentIndex: undefined,
          // Exclude from index if includedInToc is false
          index: includeInToc ? chapterIndex : undefined,
          children: sectionsOrWidgets
        }))
      }

    }
    return items
  }

  public canPerformAction(key: FlexibleDocumentPermissions): boolean {
    return canPerformAction<FlexibleDocumentPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<FlexibleDocumentResource> {
    const data = await this.flexibleDocumentService.get()
    this.setData(data.data)
    return this
  }

  public getOrderedObject(): Array<{ uuid: string, order: number }> {
    return this.items.map(createOrderObject)
  }

  public reorderItems(): FlexibleDocumentResource {
    let index = 0
    for (const item of this.items) {
      item.order = index
      index++
    }
    return this
  }

  public async getElement(uuid: string): Promise<DetailResponse<FlexibleDocumentItemResource>> {
    return this.flexibleDocumentService.getElement(uuid)
  }

  public async patch(body: FlexibleDocumentUpdateRequest): Promise<FlexibleDocumentResource> {
    const { data } = await this.multipleDocumentService.patch({ uuid: this.uuid, body })
    this.title = data.title
    this.end_date = data.end_date
    return this
  }

  public async updateExportSettings(body: FlexibleDocumentUpdateExportSettingsRequest): Promise<FlexibleDocumentResource> {
    await this.multipleDocumentService.updateExportSettings(body)
    return this
  }

  public async patchOrder(body: Pick<FlexibleDocumentUpdateItemOrderRequest, 'items'>): Promise<FlexibleDocumentResource> {
    await this.multipleDocumentService.patchElementOrder({ body: { anchor_uuid: this.uuid, ...body } })
    return this
  }

  public async addItem(item: FlexibleDocumentCreateItemRequest, index: number, templateId: any): Promise<void> {
    if (!this.flexibleDocumentService) {
      return
    }
    stateModule.setLoading(true)
    try {
      const { data: chapter } = await this.multipleDocumentService.postElement({ body: item }, templateId)
      this.items.splice(index, 0, chapter as FlexibleDocumentItemCollection)
      this.reorderItems()
      await this.patchOrder({ items: this.getOrderedObject() })
    } catch (err) {
      throw err
    } finally {
      stateModule.setLoading(false)
    }
  }

  public async patchStatus(body: FlexibleDocumentUpdateStatusRequest): Promise<FlexibleDocumentResource> {
    const { data } = await this.flexibleDocumentService.patchStatus({ body })
    this.setRawData(data)
    return this
  }

  public async reviewStatus(body: FlexibleDocumentReviewStatusRequest): Promise<FlexibleDocumentResource> {
    const { data } = await this.multipleDocumentService.reviewStatus({ body })
    this.setRawData(data)
    return this
  }

  private setItems(items: FlexibleDocumentItemCollection[]): void {
    Vue.set(this, 'items', items?.map((chapter: FlexibleDocumentItemCollection) => convertToFlexibleDocumentCollectionItem(chapter, this.project_id))
        .sort((a, b) => (a.order > b.order) ? 1 : -1) ?? [])
  }

  private setStatus(data: FlexibleDocumentResource): void {
    Vue.set(this, 'status', data.status)
    Vue.set(this, 'statuses', data.statuses)
  }

  private setData(data: FlexibleDocumentResource): void {
    this.title = data.title
    this.end_date = data.end_date
    this.permissions = data.permissions
    this.pdf_link = data.pdf_link
    this.had_sounding = data.had_sounding
    this.project_status = data.project_status

    this.setStatus(data)

    this.setItems(data.items)
  }

  private setRawData(data: IFlexibleDocumentResource): void {
    this.title = data.title
    this.end_date = data.end_date
    this.permissions = data.permissions
    this.pdf_link = data.pdf_link
    this.had_sounding = data.had_sounding
    this.project_status = data.project_status

    Vue.set(this, 'status', new FlexibleDocumentStatusResource(data.status))
    Vue.set(this, 'statuses', data.statuses.map((s) => new FlexibleDocumentStatusResource(s)))

    this.setItems(data.items)
  }

  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case 'element updated':
        this.audits.get().then()
    }
  }

  private async openSocket(): Promise<void> {
    if (!this.channel) return
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}
